import { OrigenRelavamientoService } from './detalle-relevamiento/origen-relavamiento.service';
import { Component, Injector, OnInit } from '@angular/core';
import { LogisoftStorage } from 'src/app/generico/storage/logisoft-storage';
import { BotonMenu } from '../boton-menu';
import { FooterComponent } from 'src/app/components/footer/footer.component';
import { TablaComponent } from 'src/app/generico/tabla/tabla.component';
import { BsModalService, BsModalRef } from 'ngx-bootstrap';
import { PantallaCargando } from '../pantalla-cargando';
import { ArticulosComponent } from '../articulos/articulos.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { DetalleRelevamientoComponent } from './detalle-relevamiento/detalle-relevamiento.component';
import { RelevamientoPrecioService } from './relevamiento-precio.service';
import { RelevamientoPrecio } from './relevamiento-precio';
import { CabeceraComponent } from 'src/app/components/cabecera/cabecera.component';
import { SubirArchivoComponent } from './subir-archivo/subir-archivo.component';
import Swal, { SweetAlertResult } from 'sweetalert2';
import { MensajesService } from 'src/app/generico/mensajes/mensajes.service';

@Component({
	selector: 'app-informar-articulos',
	templateUrl: './informar-articulos.component.html',
	styleUrls: ['./informar-articulos.component.scss']
})
export class InformarArticulosComponent extends PantallaCargando implements OnInit, BotonMenu {
	modalRef: BsModalRef;
	model = {
		articulo: null,
		precio: 0,
		cantidad: 0,
		observaciones: "",
		origen: null
	}

	origenes = [];
	relevamientos: RelevamientoPrecio[] = [];
	condiciones = [];

	constructor(public injector: Injector, public modalService: BsModalService, private modalAbajo: MatBottomSheet, private _servicio: RelevamientoPrecioService, private _servicioOrigenes: OrigenRelavamientoService) {
		super(injector);
		FooterComponent.pantalla = this;
		CabeceraComponent.titulo = "RELEVAMIENTO";
	}

	ngOnInit() {
		this.inicioCargando();
		this._servicioOrigenes.getAll().subscribe((rta: any[]) => {
			this.origenes = rta;
			this.finCargando();
		});
		this.cargarRelevamientos();
		this.inicioCargando();
		this._servicio.getCondicionesRelevamientos().subscribe((r: any[]) => {
			this.condiciones = r;
			this.finCargando();
		})

	}



	cargarRelevamientos() {
		this.inicioCargando();
		this._servicio.getAll().subscribe((rta: any[]) => {
			this.relevamientos = rta;
			this.finCargando();
		}, e => {
			this.finCargando();
		});
	}

	getFecha() {
		return new Date();
	}

	getNombreUsuario(): string {
		return LogisoftStorage.getNombreUsuario();
	}

	getDetalles() {
		return this.relevamientos;
	}

	//interfaz Boton Menu
	clickBotonMenu() {
		this.nuevoRelevamiento();
	}

	public nuevoRelevamientoSinArticulo() {
		let elemento = this;
		var item = {
			descripcion: ""
		};
		item.toString = function () { return this.descripcion; };
		elemento.model.articulo = item;
		elemento.model.cantidad = 1;
		elemento.abrirModalEditar(elemento.model);

	}

	public nuevoRelevamiento() {
		this.abrirModalBusqueda(ArticulosComponent, "");
		let elemento = this;
		this.modalRef.content.abrirInfo = function (item) {
			item.toString = function () { return this.descripcion; };
			elemento.model.articulo = item;
			elemento.model.cantidad = 1;
			elemento.abrirModalEditar(elemento.model);
			elemento.cerrarModalArticulo();
		};
	}

	public subirArchivo(r) {
		if (r.nombreArchivo) {
			MensajesService
			Swal.fire({
				title: "Existe un Archivo",
				icon: "question"
				, text: "¿Esta seguro de reemplazar el archivo existente?",
				showCancelButton: true,
				showCloseButton: true,
				showConfirmButton: true,
				confirmButtonText: "Si",
				"confirmButtonColor": "#d33",
				"cancelButtonColor": "#3085d6",
				cancelButtonText: "No",

			}).then((value: SweetAlertResult) => {
				if (value.value) {
					this.modalAbajo.open(SubirArchivoComponent, {
						data: {
							data: r,
							observador: this
						}
					});
				}
			})
		} else {
			this.modalAbajo.open(SubirArchivoComponent, {
				data: {
					data: r,
					observador: this
				}
			});
		}

	}

	cerrarModalArticulo() {
		this.modalRef.hide();
	}
	abrirModalEditar(model) {
		this.abrirModalDetalle(model);
	}
	botonDeshabilitado(): boolean {
		return false;
	}
	getNombre(): string {
		return "relevamiento";
	}
	//fin interfaz Boton Menu

	public abrirModalBusqueda(x, texto) {
		TablaComponent.active = 0;
		this.modalRef = this.modalService.show(x);
		this.modalRef.content.busqueda.texto = texto;
		this.modalRef.content.textoBuscar = texto;
		this.modalRef.content.setPaginacion(5);
		this.modalRef.content.iconoInfo = "fas fa-angle-right fa-lg";
		this.modalRef.content.tituloCentrado = true;
		this.modalRef.content.elemento = this;
		TablaComponent.active = 1;
	}

	abrirModalDetalle(d) {
		this.modalAbajo.open(DetalleRelevamientoComponent, {
			data: {
				detalle: Object.assign({}, d),
				real: d,
				origenes: this.origenes,
				elemento: this,
				condiciones: this.condiciones
			}
		});
	}

	actualizar() {
		this.ngOnInit();
	}

}
