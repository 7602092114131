import { Component, OnInit, AfterViewInit, Injector } from '@angular/core';
import { TablaComponent } from 'src/app/generico/tabla/tabla.component';
import { Entidad } from '../entidad';
import { ArticuloService } from '../../articulos/articulo.service';
import { BsModalService } from 'ngx-bootstrap';
import { Router } from '@angular/router';
import { EntidadesService } from '../entidades.service';

@Component({
  selector: 'app-entidad',
  templateUrl: './entidad.component.html',
  styleUrls: ['../../../generico/tabla/tabla.component.scss', './entidad.component.scss']
})
export class EntidadComponent extends TablaComponent<Entidad> implements OnInit , AfterViewInit {
  titulo = 'CLIENTES';
  accionNuevo=false;
  elemento;
  constructor(public injector:Injector,public service: EntidadesService, public modalService: BsModalService, public router: Router) {
    
    super(injector,service, modalService, router);
   
    
  }

  cerrar(){
    this.elemento.modalRef.hide();
  }
  
  }
  