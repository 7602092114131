import { Component, OnInit, AfterViewInit, Injector } from '@angular/core';
import { TablaComponent } from 'src/app/generico/tabla/tabla.component';
import { Entidad } from '../../entidades/entidad';
import { EntidadesService } from '../../entidades/entidades.service';
import { BsModalService } from 'ngx-bootstrap';
import { Router } from '@angular/router';
import { CondicionPagoService } from '../condicion-pago.service';

@Component({
  selector: 'app-condicion',
  templateUrl: './condicion.component.html',
  styleUrls: ['../../../generico/tabla/tabla.component.scss','./condicion.component.scss']
})
export class CondicionComponent extends TablaComponent<Entidad> implements OnInit , AfterViewInit {
  titulo = 'Condiciones de pago';
  accionNuevo=false;
  elemento;
  constructor(public injector:Injector,public service: CondicionPagoService, public modalService: BsModalService, public router: Router) {
    
    super(injector,service, modalService, router);
    
  }
  cerrar(){
    this.elemento.modalRef.hide();
  }
  
  }
  
