import { Injectable } from '@angular/core';
import { GenericoService } from 'src/app/generico/servicios/generico.service';
import { Familia } from './familia';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SubfamiliaService extends GenericoService<Familia> {
  urlObjeto ='articulos/subfamilias/familias/id/subfamilias';
  json = {};
  constructor(public http: HttpClient) {
    super(http);
    this.url=environment.url;
  }

    //override  
    getPaginaServidorBusqueda(pageIndex: number, pageSize: number, parametros) {     
     // parametros.push(["sort","descripcion,ASC"]);
     //return this.getAll();
      return super.getPaginaServidorBusqueda(pageIndex,pageSize,parametros);
    }
    
 //override
 getAll(){
  const params = new HttpParams()
  .set('size','1000')
  .set('sort', 'descripcion,asc');
    return <Observable<any>> this.http.get(this.getUrl(), {params});
}
}
