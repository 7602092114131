import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { AccionesGenerales } from '../acciones/acciones-generales';

@Component({
  selector: 'app-elemento-tabla',
  templateUrl: './elemento-tabla.component.html',
  styleUrls: ['./elemento-tabla.component.scss']
})
export class ElementoTablaComponent implements OnInit {
 
  mostrar:any=function(){return ""};
  getIcono:any=function(){return ""};
  eBoolean=false;
  propiedad;
  constructor(public cd:ChangeDetectorRef) {  
   
  }

  ngOnInit(): void {
  
  }
  observador:AccionesGenerales;
  private _col = { prop: "dummy." ,clase:"false",icon:null,tipo:null,estilo:null,mostrar:null };  
  
  private _elemento = { dummy: null };

  public get elemento() {
    return this._elemento;
  }
  public set elemento(value) {   
    this._elemento = value;
    this.setPropiedadChange();
    this.setMostrar();
    this.setIcono();
    this.cd.detectChanges();  
  }

  public get col() {
    return this._col;
  }
  public set col(value) {   
    this._col = value;
    this.cd.detectChanges();
  } 

  setPropiedadChange() {
    if(!this.col.prop){return;}
    const props=this.col.prop.split(".");
    var e=this.elemento;
    for (let i = 0; i < props.length; i++) {
      const p = props[i];
      e=e[p];
      if(!e){
      this.propiedad="";
      return;}
    }
    this.propiedad= e;
  }

  setMostrar() {
    if(this.col.mostrar){
      
        this.mostrar=new Function('return '+this.col.mostrar);
        return;
    }
   this.mostrar=new Function('return this.propiedad;');
  }

  setIcono() {
    if(this.col.icon){
      
      this.getIcono=new Function("return "+this.col.icon);
      return;
  }
 this.getIcono=new Function('return " "');
  }

 
}
