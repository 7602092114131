import { Injectable } from '@angular/core';
import { Estado } from './estado';
import { GenericoService } from 'src/app/generico/servicios/generico.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EstadosServicee extends GenericoService<Estado>{
  urlObjeto = 'estados-cotizacion';
  constructor(public http: HttpClient) {
    super(http);
    this.url=environment.url;
  }


  getAll(){
    return this.http.get(this.getUrl());
  }

  

}