import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ElementoTablaComponent } from '../elemento-tabla/elemento-tabla.component';

@Component({
  selector: 'app-boton',
  templateUrl: './boton.component.html',
  styleUrls: ['./boton.component.scss']
})
export class BotonComponent extends ElementoTablaComponent implements OnInit {

  constructor(public cd:ChangeDetectorRef) {
    super(cd);
  }

  ngOnInit() {
  }

}
