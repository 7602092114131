import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListaComponent } from './lista/lista.component';
import { TablaComponent } from './tabla/tabla.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {FormlyModule} from '@ngx-formly/core';

import { TextoComponent } from './formlys/texto/texto.component';
import { FieldGenericoComponent } from './formlys/field-generico/field-generico.component';
import { ElementoTablaComponent } from './formlys/elemento-tabla/elemento-tabla.component';
import { BotonComponent } from './formlys/boton/boton.component';
import { BusquedaComponent } from './formlys/busqueda/busqueda.component';
import { ModalTablaComponent } from './tabla/modal-tabla/modal-tabla.component';
import { AccionesComponent } from './formlys/acciones/acciones.component';
import { BotonIconoComponent } from './formlys/boton-icono/boton-icono.component';
import { EditarComponent } from './editar/editar.component';
import { MostrarTextoComponent } from './formlys/mostrar-texto/mostrar-texto.component';
import { MaterialModule } from '../material/material.module';
import { AutocompleteTypeComponent } from './formlys/autocomplete-type/autocomplete-type.component';
import { PanelBotonAtrasComponent } from './boton-atras/panel-boton-atras/panel-boton-atras.component';
import { IconoComponent } from './formlys/icono/icono.component';
import { TextoIconoComponent } from './formlys/texto-icono/texto-icono.component';
@NgModule({
  declarations: [ListaComponent, TablaComponent, TextoComponent, FieldGenericoComponent, ElementoTablaComponent, BotonComponent, BusquedaComponent, ModalTablaComponent, AccionesComponent, BotonIconoComponent, EditarComponent, MostrarTextoComponent, AutocompleteTypeComponent, PanelBotonAtrasComponent, IconoComponent, TextoIconoComponent],
  imports: [
    CommonModule,
    
    ReactiveFormsModule,
    FormlyModule.forRoot({types: [{
      name: 'autocomplete',
      component: AutocompleteTypeComponent,
      wrappers: ['form-field'],
    }],
    validationMessages: [
      { name: 'required', message: 'Campo requerido.' },
    ]}),
    MaterialModule  
  ],
  exports: [FormlyModule,TablaComponent, TextoComponent, FieldGenericoComponent, BotonComponent, BusquedaComponent, ModalTablaComponent, AccionesComponent,MaterialModule,PanelBotonAtrasComponent,IconoComponent,TextoIconoComponent]
})
export class GenericoModule { }
