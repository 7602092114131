import { Injectable } from '@angular/core';
import { GenericoService } from 'src/app/generico/servicios/generico.service';
import { CondicionPago } from './condicion-pago';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { Pagina } from 'src/app/generico/paginacion/pagina';
import { Entidad } from '../entidades/entidad';

@Injectable({
  providedIn: 'root'
})
export class CondicionPagoService extends GenericoService<CondicionPago> {
  urlObjeto ='condiciones-pago';
  json = require('./campos.json');  
  constructor(public http: HttpClient) {
    super(http);
    this.url=environment.url;
  }

  //override
  getPaginaServidorBusqueda(pageIndex: number, pageSize: number, parametros){
    parametros.push(['sort','descripcion,ASC']);
    return super.getPaginaServidorBusqueda(pageIndex,pageSize,parametros);
  }
 
}
