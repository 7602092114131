import { Injectable } from '@angular/core';
import { GenericoService } from 'src/app/generico/servicios/generico.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Color } from './color';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ColoresService extends GenericoService<Color>{
  urlObjeto = 'colores';
  constructor(public http: HttpClient) {
    super(http);
    this.url = environment.url;
  }


  getAll() {
    return this.http.get(environment.url + "config-color/vendedor").pipe(
      map(
        function (detallesConfiguracionColor: any) {
          return detallesConfiguracionColor.detalles.map(function (d) {
            let color = d.color;
            color.limiteDesde = d.limiteDesde;
            color.limiteHasta = d.limiteHasta;
            return color;
          }

          )
        })
    );
  }

}
