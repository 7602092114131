import { ArticuloService } from './../../pages/articulos/articulo.service';
import { EntidadesService } from 'src/app/pages/entidades/entidades.service';
import { CondicionPagoService } from 'src/app/pages/condiciones/condicion-pago.service';

import { FamiliaService } from 'src/app/pages/articulos/familia.service';
import { SubfamiliaService } from 'src/app/pages/articulos/subfamilia.service';
import { MarcaService } from 'src/app/pages/articulos/marca.service';


 export class ServiciosConfig {    
    static servicios = { 
        entidad:{servicio:EntidadesService},
        condicion:{servicio:CondicionPagoService},
        articulo: {servicio:ArticuloService},
        familia:{servicio:FamiliaService},
		subfamilia: { servicio: SubfamiliaService },
		marca:{servicio:MarcaService}
    /*generadoPorScript*/};
}
