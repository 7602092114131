import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ElementoTablaComponent } from '../elemento-tabla/elemento-tabla.component';

@Component({
  selector: 'app-texto-icono',
  templateUrl: './texto-icono.component.html',
  styleUrls: ['./texto-icono.component.scss']
})
export class TextoIconoComponent extends ElementoTablaComponent implements OnInit,AfterViewInit  {
  
  constructor(public cd:ChangeDetectorRef) {
    super(cd);
  }

  ngAfterViewInit(): void {    
  }

  ngOnInit() {      
  }
}
