import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-cargando',
  templateUrl: './cargando.component.html',
  styleUrls: ['./cargando.component.scss']
})
export class CargandoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }


  static mostrarCargando(){
    Swal.fire(
      {html:require("./cargando.component.html"),
      showConfirmButton:false,
      allowOutsideClick: false,
      allowEscapeKey: false
    });
    Swal.showLoading();
  }
}
