import { Injectable } from '@angular/core';
import { text } from '@angular/core/src/render3';
import Swal, { SweetAlertResult } from 'sweetalert2';
import { EntidadMensaje } from './entidad-mensaje';
export enum TipoMensaje {
	EXITO = "EXITO",
	ERROR = "ERROR",
	ELIMINAR = "ELIMINAR",
	EXITO_ELIMINAR = "EXITO_ELIMINAR"
}
@Injectable({
	providedIn: 'root'
})
export class MensajesService {


	jsonMensajes = require('./mensajes.json');
	constructor() { }

	mostrarMensaje(tipo: TipoMensaje, entidadMensaje: EntidadMensaje): Promise<SweetAlertResult> {
		switch (tipo) {
			case TipoMensaje.EXITO:
				return this.mensajeExito(entidadMensaje);
			case TipoMensaje.ELIMINAR:
				return this.mensajeEliminar(entidadMensaje);
			case TipoMensaje.EXITO_ELIMINAR:
				return this.mensajeExitoEliminar(entidadMensaje);
			default:
				break;
		}
	}

	mensajeExitoEliminar(entidadMensaje: EntidadMensaje): Promise<SweetAlertResult> {
		let mensaje = Object.assign({}, this.jsonMensajes.EXITO_ELIMINAR);
		mensaje.text = entidadMensaje.getSingularConArticulo() + mensaje.text;
		return Swal.fire(mensaje);
	}

	mensajeEliminar(entidadMensaje: EntidadMensaje): Promise<SweetAlertResult> {
		let mensaje = Object.assign({}, this.jsonMensajes.ELIMINAR);
		mensaje.text = mensaje.text + entidadMensaje.getSingularConArticulo() + " ?";
		return Swal.fire(mensaje);
	}
	mensajeExito(entidadMensaje: EntidadMensaje): Promise<SweetAlertResult> {
		let mensaje = Object.assign({}, this.jsonMensajes.EXITO);
		mensaje.text = entidadMensaje.getSingularConArticulo() + mensaje.text;
		return Swal.fire(mensaje);
	}

	static mensajeError(e){
		Swal.fire({
			title:"Error",
			text:e
		});
	}
}
