import { Component, OnInit, Input, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { ElementoTablaComponent } from '../elemento-tabla/elemento-tabla.component';


@Component({
  selector: 'app-texto',
  templateUrl: './texto.component.html',
  styleUrls: ['./texto.component.scss']
})
export class TextoComponent extends ElementoTablaComponent implements OnInit,AfterViewInit  {
  
  constructor(public cd:ChangeDetectorRef) {
    super(cd);
  }

  ngAfterViewInit(): void {    
  }

  ngOnInit() {      
  }

 

 

}
