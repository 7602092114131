import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { CotizacionComponent } from '../cotizacion.component';
import { CuitService } from '../cuit.service';

@Component({
  selector: 'app-nuevo-cliente',
  templateUrl: './nuevo-cliente.component.html',
  styleUrls: ['./nuevo-cliente.component.scss']
})
export class NuevoClienteComponent implements OnInit {
  
cliente={
  razonSocial: "",
  cuit: null,
  telefono:null,
  cuitString:null
}
  constructor(private cd:ChangeDetectorRef,private service:CuitService,@Inject(MAT_BOTTOM_SHEET_DATA) public data: any,private _bottomSheetRef: MatBottomSheetRef<CotizacionComponent>) { }

  ngOnInit() {
  }

 
  convertirCuit(cuit): string {    
   return cuit.toString().substr(0,2)+"-"+cuit.toString().substr(2,cuit.toString().length-3)+"-"+cuit.toString().substr(cuit.toString().length-1,1);
  }

  cancelar(){
    this._bottomSheetRef.dismiss();
  }

  aceptar(){
    this.cliente.cuitString=this.convertirCuit(this.cliente.cuit);   
    this.data.elemento.nuevoCliente(this.cliente);
    this._bottomSheetRef.dismiss();
  }

  buscar(){
    this.service.get(this.cliente.cuit).subscribe(r=>{
      this.cliente.razonSocial=this.service.getRazonSocial(r);
      this.cd.detectChanges();
    })
  }

  deshabilitado(){
    return !this.cliente.cuit || !this.cliente.razonSocial || !this.cliente.telefono|| this.cliente.cuit.length<10 || this.cliente.razonSocial.length<3 || this.cliente.telefono<1000000;
  }
}
