import { Component, OnInit, Inject, AfterViewInit, AfterContentInit, ElementRef, ViewChild, AfterViewChecked } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { CotizacionComponent } from '../cotizacion.component';
import { FormControl, FormGroup, Validators, ValidatorFn, ValidationErrors } from '@angular/forms';
import { DetalleCotizacion } from '../detalle-cotizacion';
import { LogisoftUtil } from '../../../generico/logisoft-util';
@Component({
	selector: 'app-detalle-cotizacion',
	templateUrl: './detalle-cotizacion.component.html',
	styleUrls: ['./detalle-cotizacion.component.scss']
})



export class DetalleCotizacionComponent implements OnInit {
	form;
	form2;
	form3;
	form4;
	multiplicador = 1;
	valido = true;
	numeroLargo = false;
	observador: CotizacionComponent;
	@ViewChild("cantidadInput") cantidadInput: ElementRef;
	@ViewChild("doa") doaInput: ElementRef;
	constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: any, private _bottomSheetRef: MatBottomSheetRef<CotizacionComponent>) {
		this.form = new FormGroup({
			'cantidad': new FormControl(this.data.detalle.cantidad, [Validators.required])
		});
		this.form2 = new FormGroup({
			'doa': new FormControl(this.data.detalle.importeDOA, [])
		});
		this.form3 = new FormGroup({
			'dpk': new FormControl(this.data.detalle.importeDPK, [])
		});
		this.form4 = new FormGroup({
			'dca': new FormControl(this.data.detalle.importeDCA, [])
		});
		if (data.multiplicador) {
			this.multiplicador = data.multiplicador;
		}
		this.observador = data.observador ? data.observador : null;

	}

	ngOnInit() {
		
	}


	getSubtotalDescuento() {
		if (this.data.detalle.descuento && this.data.detalle.descuento > 0) {
			return "Subtotal: USD " + this.data.detalle.precio.precioVenta + " x " + this.data.detalle.cantidad + " (cant) - %" + this.data.detalle.descuento + " (desc)";
		}
		return "Subtotal: USD " + this.data.detalle.precio.precioVenta + " x " + this.data.detalle.cantidad + " (cant)";
	}



	aceptar() {
		this.getReal().cantidad = this.data.detalle.cantidad;
		this.getReal().importeDOA = this.data.detalle.importeDOA;
		this.getReal().importeDPK = this.data.detalle.importeDPK;
		this.getReal().importeDCA = this.data.detalle.importeDCA;
		this.getReal().actualizarDescuento(this.getDetalle().porcentajeDescuento);
		if (this.getReal().noExiste) {
			this.getReal().noExiste = false;
			this.data.detalles.push(this.getReal());
		}
		this.observador.actualizarDovs();
		this._bottomSheetRef.dismiss();
	}

	cancelar() {
		this._bottomSheetRef.dismiss();
	}

	validaMultiplos() {

	}

	getReal(): DetalleCotizacion {
		return <DetalleCotizacion>this.data.real;
	}

	getDetalle(): DetalleCotizacion {
	
		return <DetalleCotizacion>this.data.detalle;
	}

	getDescripcion() {
		return this.getDetalle().descripcion;
	}

	getCodigo() {
		return this.getDetalle().codigoAlfanumerico;
	}

	cambioDescuento(d) {

	}

	valid() {
		return !this.form.controls['cantidad'].invalid;
	}

	getPrecioIndividual() {
		let detalle = (<DetalleCotizacion>this.data.detalle);		

		return detalle.precioVentaBase * (1 - detalle.porcentajeDescuento / 100);
	}

	cambioCantidad(cantidad) {
		//solo para acomodar la pantalla y que se vea el numero		
		this.numeroLargo = Math.trunc(this.getDetalle().precioVentaBase * this.getDetalle().cantidad).toString().length > 5;

		//para hacer la validacion en tiempo real, el validator presentaba fallas
		this.valido = (cantidad && (cantidad % this.multiplicador) == 0);

	}

	cambioDOA(n) {
		if(n){
			this.data.detalle.importeDPK=0;
		}
	}
	cambioDPK(n) {
		if(n){
			this.data.detalle.importeDOA=0;
		}
	}
	cambioDCA(n) {
		
	}

}
