import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-item-carrousel',
  templateUrl: './item-carrousel.component.html',
  styleUrls: ['./item-carrousel.component.scss']
})
export class ItemCarrouselComponent implements OnInit {
  @Input()
  imagen;
  @Input()
  textoL1;
  @Input()
  textoL2;
  @Input()
  link;
  constructor() {
    
   }

  ngOnInit() {
  }

}
