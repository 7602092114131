import { ArticuloService } from './articulo.service';
import { Articulo } from './articulo';
import { Component, OnInit, AfterViewInit, Injector } from '@angular/core';
import { TablaComponent } from 'src/app/generico/tabla/tabla.component';
import { BsModalService } from 'ngx-bootstrap';
import { Router } from '@angular/router';
import { FamiliaService } from './familia.service';
import { Paginador } from 'src/app/generico/paginacion/paginador';
import { SubfamiliaService } from './subfamilia.service';
import { MarcaService } from './marca.service';


@Component({
	selector: 'app-articulos',
	templateUrl: './articulos.component.html',
	styleUrls: ['./articulos.component.scss']
})
export class ArticulosComponent extends TablaComponent<Articulo> implements OnInit, AfterViewInit {
	titulo = 'Artículos';
	familias = [];
	familia;
	familiaService: FamiliaService;
	subfamilias = [];
	subfamilia;
	subfamiliaService: SubfamiliaService;
	marcas = [];
	marca;
	marcaService: MarcaService;
	accionNuevo = false;
	elemento;
	habilitarSubfamilia = false;
	constructor(public injector: Injector, public service: ArticuloService, public modalService: BsModalService, public router: Router) {
		super(injector, service, modalService, router);
		this.familiaService = injector.get(FamiliaService);
		this.marcaService = injector.get(MarcaService);
		// this.subfamiliaService=injector.get(SubfamiliaService);
	}

	ngOnInit() {
		this.marcaService.getAll().subscribe((r: any) => {
			this.marcas = r;
		});
		this.familiaService.getAll().subscribe((r: any) => {
			this.familias = r;
		});
		/*this.subfamiliaService.getAll().subscribe((sf:any)=>{
		  this.subfamilias=sf;
		});*/
		super.ngOnInit();

	}

	getParametros() {
		let params = [];
		if (this.familia) {
			if (this.familia.id) {
				params.push(["familia", this.familia.id]);
			}
		}
		if (this.subfamilia) {
			if (this.subfamilia.id) {
				params.push(["subfamilia", this.subfamilia.id]);
			}
		}
		if (this.marca) {
			if (this.marca.id) {
				params.push(["marca", this.marca.id]);
			}
		}
		if (!this.textoBuscar) {
			this.textoBuscar = "";
		}
		params.push(["filtro", this.textoBuscar]);
		return params;
	}


	cerrar() {
		this.elemento.modalRef.hide();
	}

	changeFamilia(evt) {
		this.familia = evt;
		if (!evt.id) {
			this.subfamilia = null;
			this.subfamilias = [];
		} else {

			this.subfamilias = evt.subfamilias;
		}
		this.habilitarSubfamilia = evt.id;
		this.buscar(this.textoBuscar);
	}

	changeSubfamilia(evt) {
		this.subfamilia = evt;
		this.buscar(this.textoBuscar);
	}

	changeMarca(evt) {
		this.marca = evt;
		this.buscar(this.textoBuscar);
	}




	ngAfterViewInit(): void {
		super.ngAfterViewInit();
		this.inicioConstructor();
	}


}
