import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BotonMenu } from 'src/app/pages/boton-menu';
import { InicioComponent } from 'src/app/pages/inicio/inicio.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
static icono="fa-plus";
static clase="fondo-naranja";
static pantalla: BotonMenu;
	
  constructor(public router:Router) { }


  disabledF(){
    
    return FooterComponent.pantalla?FooterComponent.pantalla.botonDeshabilitado():true;
  }
  ngOnInit() {
  }

  abrirHome(){
    this.router.navigate(['inicio']);
  }

  abrirCotizacion(){
    this.router.navigate(['cotizacion']);
  }

  getClase(){
    return FooterComponent.clase;
  }

  getIcono(){
    return FooterComponent.icono;
  }

  clickF(){
    FooterComponent.pantalla.clickBotonMenu();
  }

  isHome(){
   
   
    return FooterComponent.pantalla?FooterComponent.pantalla.getNombre()=='inicio':false;
  }
}
