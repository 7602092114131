import { Articulo } from './articulo';
import { GenericoService } from './../../generico/servicios/generico.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ArticuloService extends GenericoService<Articulo> {
  urlObjeto ='articulos/filtro-cotizacion';
  json = require('./campos.json');

  constructor(public http: HttpClient) {
    super(http);
    this.url=environment.url;
  }

    //override  
    getPaginaServidorBusqueda(pageIndex: number, pageSize: number, parametros) { 
      parametros.push(["sort","descripcion,ASC"]);
      parametros.push(["pagination",true]);
      return super.getPaginaServidorBusqueda(pageIndex,pageSize,parametros);
    }
    

}
