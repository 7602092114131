import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CuitService {
 

  constructor(private http:HttpClient) { }

  get(cuit):Observable<any>{
    return this.http.get("https://afip.tangofactura.com/Rest/GetContribuyente?cuit="+cuit.toString());
  }
  getRazonSocial(r: any): any {
    return r.Contribuyente.nombre;
   }
}
