import { CotizacionService } from './../cotizacion/cotizacion.service';
import { Component, OnInit, ViewChild, HostListener, Injector, ChangeDetectorRef } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { FooterComponent } from 'src/app/components/footer/footer.component';
import { BotonMenu } from '../boton-menu';
import { Router } from '@angular/router';
import { PantallaCargando } from '../pantalla-cargando';
import { TablaComponent } from 'src/app/generico/tabla/tabla.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { Cotizacion } from '../cotizacion/cotizacion';
import { CondicionComponent } from '../condiciones/condicion/condicion.component';
import { ColoresService } from '../cotizacion/colores.service';
import { LogisoftStorage } from 'src/app/generico/storage/logisoft-storage';
import Swal, { SweetAlertIcon, SweetAlertResult } from 'sweetalert2';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { EstadosComponent } from './estados/estados.component';
import { EstadosServicee } from './estados.service';
import { ListaCotizacionesComponent } from '../lista-cotizaciones/lista-cotizaciones.component';
import { CabeceraComponent } from 'src/app/components/cabecera/cabecera.component';
@Component({
	selector: 'app-inicio',
	templateUrl: './inicio.component.html',
	styleUrls: ['./inicio.component.scss']
})
export class InicioComponent extends PantallaCargando implements OnInit, BotonMenu {
	items = [
		{ imagen: "assets/img/dashboard/nueva-cotizacion.jpg", textoL1: "Nueva", textoL2: "Cotización", link: "/cotizacion" },
		{ imagen: "assets/img/dashboard/relevamiento-precio.jpg", textoL1: "Relevamiento", textoL2: "de Precios", link: "/relevamiento" }
		, { imagen: "assets/img/dashboard/ver-cotizacion.jpg", textoL1: "Mis", textoL2: "Cotizaciones", link: "/cotizaciones-filtro" }
		/*, { imagen: "assets/img/dashboard/consultar-precio.jpg", textoL1: "Consultar", textoL2: "Lista", link: "" }
		, { imagen: "assets/img/dashboard/nuevo-cliente.jpg", textoL1: "Nuevo", textoL2: "Cliente", link: "" }*/

	];
	@HostListener('scroll', ['$event'])
	scrolled() {
		//this.listaCotizaciones.cotizaciones=this.listaCotizaciones.cotizaciones.concat(this.listaCotizaciones.cotizaciones);
	}

	
	@ViewChild("listaCotizaciones")
	listaCotizaciones:ListaCotizacionesComponent;
	modalRef: BsModalRef;

	constructor(public injector:Injector,private estadosService: EstadosServicee, private modalAbajo: MatBottomSheet, private _servicio: CotizacionService, private router: Router, public modalService: BsModalService, public coloresService: ColoresService) {
		super(injector);
		FooterComponent.icono = "fa-plus";
		FooterComponent.clase = "fondo-naranja";
		FooterComponent.pantalla = this;
		CabeceraComponent.titulo="MENÚ";
	}


	customOptions: OwlOptions = {
		loop: true,
		mouseDrag: true,
		touchDrag: true,
		pullDrag: true,
		dots: false,
		navSpeed: 700,
		items: 2,
		autoHeight: true,
		autoWidth: true,
		nav: false,
		responsive: {
			// breakpoint from 0 up
			0: {
				items: 2
			},
			// breakpoint from 480 up
			480: {
				items: 2
			},
			// breakpoint from 768 up
			768: {
				items: 2
			}
		}
	}

	ngOnInit() {
		this.listaCotizaciones.actualizar();
	}


	public set cotizaciones(cotizaciones){
		this.listaCotizaciones.cotizaciones=cotizaciones;
	}

	private cargarCotizaciones() {
		this._servicio.getAll().subscribe((rta: any[]) => {
			this.cotizaciones = Cotizacion.conversionArray(rta);
			this.finCargando();
		});
	}

	

	
	//interfaz botonmenu
	clickBotonMenu() {
		this.router.navigate(['cotizacion']);
	}

	botonDeshabilitado(): boolean {
		return false;
	}

	getNombre(): string {
		return 'inicio';
	}

	


	public abrirModalBusqueda(x, texto) {
		TablaComponent.active = 0;
		this.modalRef = this.modalService.show(x);
		this.modalRef.content.busqueda.texto = texto;
		this.modalRef.content.textoBuscar = texto;
		this.modalRef.content.setPaginacion(5);
		this.modalRef.content.iconoInfo = "fas fa-angle-right fa-lg";
		this.modalRef.content.tituloCentrado = true;
		this.modalRef.content.elemento = this;
		TablaComponent.active = 1;
	}

	

}
