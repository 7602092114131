import { Component, OnInit, ChangeDetectorRef, Input } from '@angular/core';
import { ElementoTablaComponent } from '../elemento-tabla/elemento-tabla.component';
import { Configuracion } from 'src/app/generico/configuracion';
import { BotonIconoEstilo } from './boton-icono-estilo';
import { AccionesGenerales } from '../acciones/acciones-generales';

@Component({
  selector: 'app-boton-icono',
  templateUrl: './boton-icono.component.html',
  styleUrls: ['./boton-icono.component.scss']
})
export class BotonIconoComponent extends ElementoTablaComponent implements OnInit {
  
  constructor(public cd:ChangeDetectorRef) {
    super(cd);
  }

  ngOnInit() {
  }

  getClase(){
    if(this.col.estilo!=null){
      return Configuracion.obtenerClaseIcono(this.col.estilo);
    }else{
      return new BotonIconoEstilo(this.col.icon,this.col.clase);
    }
  }

  click(){
    if(this.col.estilo){
      this.ejecutarFuncionGenerico();
    }else{
      this.ejecutarFuncion(this.elemento);
    }
  }

  ejecutarFuncion(item: any) {
    throw new Error("Method not implemented.");
  }
  
  ejecutarFuncionGenerico() {    
      switch (this.col.estilo) {
          case "editar":
              this.observador.editar((<any>this.elemento).id);
              break;
          case "eliminar":
            this.observador.eliminar((<any>this.elemento).id);
            break;
          case "ver":
            this.observador.ver((<any>this.elemento).id);
             break;          
          default:
              console.log("no esta implementado el metodo "+this.col.estilo);
              break;
  }
  }

}
