import { EntidadBackend } from '../entidad-backend';
import { LogisoftUtil } from 'src/app/generico/logisoft-util';

export class DetalleCotizacion extends EntidadBackend<DetalleCotizacion>{


	idArticulo: number;
	descripcion: string;
	cantidad: number;
	color;
	linea: string = "";
	//HARCODED
	fraccionVenta = 1;
	codigoAlfanumerico: string;
	metodoPrecio: string;

	//precio-margen-costo
	precioCosto: number;
	precioListaBase: number;
	precioVentaBase: number;
	porcentajeDescuento: number;
	margen: number;
	precioVenta: number;
	importeSubTotalLinea: number;
	importeDescuento: number;
	importeTotalLinea: number;
	tipoInteres: string;
	interesAUsar: number;
	dov: boolean;
	doa: boolean;
	importeDOA: number = 0;
	//descuentos nuevos pack
	importeDPK: number = 0;
	importeDCA: number = 0;
	importeDCON: number = 0;
	dpk: boolean;//descuento pack
	dca: boolean;//descuento calibre
	dcon: boolean; //descuento condicion
	ddcon: number; // % descuento
	// atributos no persistentes     
	deslizar;
	noExiste;

	getImporteDOAFinanciado() {
		return this.importeDOA * (1 + this.interesAUsar);
	}

	getImporteDPKFinanciado() {
		return this.importeDPK * (1 + this.interesAUsar);
	}

	getImporteDCAFinanciado() {
		return this.importeDCA * (1 + this.interesAUsar);
	}

	convertirRespuesta(r: any) {
		let a = super.convertirRespuesta(r);
		a.precioCosto = a.precioCosto;
		return a;
	}

	convertir(d: any): DetalleCotizacion {
		this.precioListaBase = d.precio.precioListaBase;
		this.idArticulo = d.articulo.id;
		this.descripcion = d.articulo.descripcion;
		this.codigoAlfanumerico = d.articulo.codigoAlfanumerico;
		this.cantidad = d.cantidad;
		this.fraccionVenta = d.articulo.fraccionVenta;
		this.linea = "";
		this.precioVentaBase = d.precio.precioVenta;
		this.porcentajeDescuento = d.descuento;
		this.precioVenta = this.precioVentaBase * (1 - this.porcentajeDescuento / 100);
		this.precioCosto = d.precio.precioCosto;
		this.importeDescuento = this.precioVentaBase * this.porcentajeDescuento / 100 * this.cantidad;
		this.tipoInteres = d.precio.tipoInteres;
		this.interesAUsar = d.precio.interesAUsar;
		this.dov = d.precio.dov;
		this.doa = d.precio.doa;
		this.dca = d.precio.dca;
		this.dpk = d.precio.dpk;
		this.dcon = d.precio.dcon;
		this.ddcon = d.precio.ddcon;
		this.importeDOA = d.importeDOA ? d.importeDOA : 0;
		return this;
	}

	getDescuentoPantalla() {
		return (this.precioVentaBase * this.cantidad) * this.porcentajeDescuento
			/ (-100) - ((this.getImporteDOAFinanciado() + this.getImporteDPKFinanciado() + this.getImporteDCAFinanciado()) * this.cantidad);
	}

	actualizarPrecio(precio) {
		this.precioVentaBase = precio.precioVenta;
		this.actualizar();
	}

	public actualizar() {
		this.precioVenta = this.precioVentaBase * (1 - this.porcentajeDescuento / 100);
		this.importeSubTotalLinea = this.cantidad * this.precioVentaBase;
		this.importeDescuento = this.getDescuentoDecimal() * this.precioVentaBase * this.cantidad;
	}

	actualizarDescuento(porcentajeDescuento: number) {
		this.porcentajeDescuento = porcentajeDescuento;
		this.actualizar();
	}

	getDescuentoDecimal() {
		return this.porcentajeDescuento / 100;
	}

	getTotalLinea() {
		return this.getPrecioUnitarioConFinanciacion() * this.cantidad;
	}

	getPrecioUnitarioConFinanciacion() {
		return (this.precioVentaBase * (1 - this.porcentajeDescuento / 100) -
			((this.getImporteDOAFinanciado() + this.getImporteDCAFinanciado() + this.getImporteDPKFinanciado())));
	}

	getLeyendaDescuentoCondicionPago(): string {
		if (this.dcon) {
			return this.ddcon + " % Dto. Con. Pago"
		}
		return "";
	}

}
