import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { FormlyMatToggleModule } from '@ngx-formly/material/toggle';
import { MatListModule } from '@angular/material/list';
import { MatTableModule } from '@angular/material/table';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    FormsModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatStepperModule,
    MatFormFieldModule,
    MatInputModule,
    FormlyMaterialModule,
    FormlyMatToggleModule,
    MatListModule,
    MatTableModule,
    MatIconModule,
    MatButtonModule,
    MatBottomSheetModule,
    MatSlideToggleModule
   
  ],
  exports: [
    ReactiveFormsModule,
    MatAutocompleteModule,
    FormsModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatStepperModule,
    MatFormFieldModule,
    MatInputModule,
    FormlyMaterialModule,
    FormlyMatToggleModule,
    MatListModule,
    MatTableModule,
    MatIconModule,
    MatButtonModule,
    MatBottomSheetModule,
  MatSlideToggleModule
  ]
})
export class MaterialModule { }
