import { Component, OnInit, ViewChild, AfterViewInit, Injector } from '@angular/core';
import { OwlOptions, CarouselComponent } from 'ngx-owl-carousel-o';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { ArticulosComponent } from '../articulos/articulos.component';
import { EntidadComponent } from '../entidades/entidad/entidad.component';
import { CondicionComponent } from '../condiciones/condicion/condicion.component';
import { CotizacionService, DescuentoCondicion } from './cotizacion.service';
import Swal, { SweetAlertResult } from 'sweetalert2';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { DetalleCotizacionComponent } from './detalle-cotizacion/detalle-cotizacion.component';
import { MensajesService } from 'src/app/generico/mensajes/mensajes.service';
import { EntidadMensaje } from 'src/app/generico/mensajes/entidad-mensaje';
import { TablaComponent } from 'src/app/generico/tabla/tabla.component';
import { LogisoftStorage } from 'src/app/generico/storage/logisoft-storage';
import { NuevoClienteComponent } from './nuevo-cliente/nuevo-cliente.component';
import { FooterComponent } from 'src/app/components/footer/footer.component';
import { DetalleCotizacion } from './detalle-cotizacion';
import { Cotizacion } from './cotizacion';
import { BotonMenu } from '../boton-menu';
import { Router, ActivatedRoute } from '@angular/router';
import { ArticuloService } from '../articulos/articulo.service';
import { CargandoComponent } from '../cargando/cargando.component';
import { PantallaCargando } from '../pantalla-cargando';
import { ColoresService } from './colores.service';
import { LogisoftUtil } from 'src/app/generico/logisoft-util';
import { CabeceraComponent } from 'src/app/components/cabecera/cabecera.component';
import { CortevaService, DOV } from 'src/app/services/corteva.service';
import { Entidad } from '../entidades/entidad';

@Component({
	selector: 'app-cotizacion',
	templateUrl: './cotizacion.component.html',
	styleUrls: ['./cotizacion.component.scss']
})
export class CotizacionComponent extends PantallaCargando implements OnInit, BotonMenu, AfterViewInit {
	descuentoCondicion: string = null;



	actualizarDovs() {
		this.model.cantBolsasDOV = 0;
		this.model.importeDOA = 0;
		this.model.importeDPK = 0;
		this.model.importeDCA = 0;
		this.model.detalles.forEach(d => {
			if (d.doa) {
				this.model.importeDOA += (d.importeDOA * d.cantidad);
			}
			if (d.dpk) {
				this.model.importeDPK += (d.importeDPK * d.cantidad);
			}
			if (d.dca) {
				this.model.importeDCA += (d.importeDCA * d.cantidad);
			}
			if (d.dov) {
				this.model.cantBolsasDOV += d.cantidad;
			}
		});
		let c = this.model.cantBolsasDOV;
		this.model.importeDOV = 0;
		for (let index = 0; index < CotizacionComponent.DOVs.length; index++) {
			const dov = CotizacionComponent.DOVs[index];
			if (c >= dov.cantDesde && (dov.cantHasta ? c <= dov.cantHasta : true)) {
				this.model.importeDOV = dov.importeDescuento * c;
				this.model.dovunitario = dov.importeDescuento;
				break;
			}
		}
		this.model.importeDCON = this.model.getImporteDCON();
	}




	@ViewChild(CarouselComponent) carousel: CarouselComponent;
	modalRef: BsModalRef;
	carouselOcultar = false;
	observacionesOcultar = true;
	customOptions: OwlOptions = {
		loop: false,
		mouseDrag: false,
		touchDrag: false,
		pullDrag: false,
		dots: false,
		navSpeed: 350,
		items: 1,
		nav: false
		//EFECTO TARJETAS QUE SE VE LA SIGUIENTE
		, stagePadding: 20,
		margin: 10,
		responsive: {
			// breakpoint from 0 up
			0: {
				items: 1
			},
			// breakpoint from 480 up
			480: {
				items: 1
			},
			// breakpoint from 768 up
			768: {
				items: 2
			},
			980: {
				items: 3
			}
		}
	}
	descuentos;
	model: Cotizacion = new Cotizacion();
	articulo;

	modo: MODOS = MODOS.NUEVO;
	static DOVs: DOV[] = [];

	constructor(public injector: Injector, public cortevaService: CortevaService, public coloresService: ColoresService, private rutaActiva: ActivatedRoute, private mensajes: MensajesService, public modalService: BsModalService, private service: CotizacionService, private modalAbajo: MatBottomSheet, public router: Router) {
		super(injector);
		FooterComponent.icono = "fa-check";
		FooterComponent.clase = "fondo-naranja";
		FooterComponent.pantalla = this;
		CabeceraComponent.titulo = "NUEVA COTIZACION";
		if (rutaActiva.snapshot.params.id) {
			if (rutaActiva.snapshot.params.accion == "ver") {
				FooterComponent.icono = "fa-home";
				FooterComponent.clase = "fondo-naranja";
				this.clickBotonMenu = function () {
					this.router.navigate(['inicio']);
				}
			}
			this.cargarCotizacion(rutaActiva.snapshot.params.id);
		}

	}
	ngAfterViewInit(): void {
		this.inicioCargando();
		if (!LogisoftStorage.isColores()) {
			this.coloresService.getAll().subscribe(r => {
				LogisoftStorage.setColores(r);
				this.finCargando();
			}, e => {
				this.finCargando();
				console.log(e);
			});
		} else { this.finCargando(); }
		if (this.rutaActiva.snapshot.params.id) {
			this.carousel.next();
			this.carousel.next();

		}

		this.inicioCargando();
		this.cortevaService.getDOVs().subscribe(dovs => {
			CotizacionComponent.DOVs = dovs;
			this.finCargando();
		}, e => {
			this.finCargando();
			this.router.navigate(["inicio"]).finally(() => {
				alert("No es posible obtener los Descuentos por volúmen...")

			});

		})


	}


	ngOnInit() {
	}

	getTotal() {
		return this.getPrecio() - this.getAhorro() - this.model.getImporteDOVFinanciado() - this.model.getImporteDescuentosFinanciado() - this.model.getImporteDCON();
	}

	swipeCarousel() {

		if (this.carouselOcultar) {
			document.getElementById("carousel").classList.remove("animated");
			document.getElementById("carousel").classList.remove("slideOutUp");
			document.getElementById("carousel").classList.add("animated");
			document.getElementById("carousel").classList.add("slideInDown");
		} else {
			document.getElementById("carousel").classList.remove("animated");
			document.getElementById("carousel").classList.remove("slideInDown");
			document.getElementById("carousel").classList.add("animated");
			document.getElementById("carousel").classList.add("slideOutUp");
		}
		this.carouselOcultar = !this.carouselOcultar;
	}

	getNombreUsuario(): string {
		return LogisoftStorage.getNombreUsuario();
	}

	getVendedor() {
		return LogisoftStorage.getNombreUsuario();
	}

	getRazonSocial() {
		if (this.model.idEntidad) {
			return this.model.razonSocial;
		}
		return "";
	}
	getIdRazonSocial() {
		if (this.model.idEntidad) {
			return this.model.idEntidad + "";
		}
		return "";
	}

	getCondicion() {
		if (this.model.idCondicionPago != undefined || this.model.idCondicionPago != null) {
			return this.model.nombreCondicionPago;
		}
		return "";
	}

	getIdCondicion() {
		if (this.model.idCondicionPago) {
			return this.model.idCondicionPago + "";
		}
		return "";
	}

	getFecha() {
		if (this.model.fechaCreacion) {
			return this.model.fechaCreacion;
		}
		return new Date();
	}

	getDetalles() {
		return this.model.detalles;
	}

	getPrecio() {
		let precio = 0;
		if (!this.model.detalles) {
			return 0;
		}
		this.model.detalles.forEach(d => {
			precio = precio + d.importeSubTotalLinea;
		});
		return precio;
	}


	getAhorro(): number {
		let ahorro = 0;
		for (let i = 0; i < this.model.detalles.length; i++) {
			ahorro = ahorro + this.model.detalles[i].importeDescuento;
		}
		return ahorro;
	}



	getColor() {
		let param1 = 0;
		let param2 = 0;
		if (this.model.detalles ? (this.model.detalles.length == 0) : false) {
			return "";
		}
		this.model.detalles.forEach((d: DetalleCotizacion) => {
			param1 = param1 + (d.precioListaBase * (1 - d.porcentajeDescuento / 100) * d.cantidad);
			param2 = param2 + (d.precioCosto * d.cantidad);
		});
		return this.getColorHexa(((param1 / param2 - 1) * 100));
	}

	getColorHexa(n: number) {
		this.model.margenTotal = n;
		return this.model.getColor();
		/*if(n>15){
			return '#1f7310';
		}
		if(n>7){
			return '#ffe211'
		}
		return '#e63f4e';*/
	}

	siguiente() {
		this.carousel.next();
	}
	atras() {
		this.carousel.prev();
	}
	public abrirModalBusqueda(x, texto) {
		TablaComponent.active = 0;
		this.modalRef = this.modalService.show(x);
		this.modalRef.content.busqueda.texto = texto;
		this.modalRef.content.textoBuscar = texto;
		this.modalRef.content.setPaginacion(5);
		this.modalRef.content.iconoInfo = "fas fa-angle-right fa-lg";
		this.modalRef.content.tituloCentrado = true;
		this.modalRef.content.elemento = this;
		TablaComponent.active = 1;
	}

	abrirArticulos() {
		if (this.model.idCondicionPago == null || this.model.idCondicionPago == undefined) { return; }
		let elemento = this;
		let texto = "";
		if (this.articulo && !this.articulo.id && this.articulo.length > 0) {
			texto = this.articulo;
		}
		this.abrirModalBusqueda(ArticulosComponent, texto);

		this.modalRef.content.abrirInfo = function (item) {
			elemento.inicioCargando();
			item.toString = function () { return this.descripcion; }
			elemento.articulo = item;
			/*	Swal.fire({
					allowOutsideClick: false,
					icon: 'info',
					text: 'Espere por favor...'
				});*/
			elemento.cargarArticulo(elemento);
		};
	}


	cargarArticulo(elemento?: CotizacionComponent) {
		if (!elemento) {
			elemento = this;
		}

		(<CotizacionService>elemento.service).getPrecio(elemento.model.idCondicionPago, 2, elemento.articulo.id, elemento.model.idEntidad).subscribe(
			(r: any) => {
				elemento.agregarArticulo(r);

			}, (e => {
				//elemento.model.precio = 0;
				Swal.fire({
					allowOutsideClick: false,
					icon: 'error',
					title: 'Error',
					text: e
				});

			})
		);
	}

	abrirEntidades() {
		let elemento = this;
		if (elemento.model.clonable !== undefined && !elemento.model.clonable) {
			Swal.fire({
				allowOutsideClick: false,
				icon: 'error',
				title: 'Error',
				text: 'No es posible cambiar de cliente a esta cotización.'
			});
			return;
		}
		let texto = "";
		if (this.model.razonSocial && !this.model.idEntidad && this.model.razonSocial.length > 0) {
			texto = this.model.razonSocial;
		}
		this.abrirModalBusqueda(EntidadComponent, texto);

		this.modalRef.content.abrirInfo = function (item) {
			if (elemento.getDetalles() && elemento.getDetalles().length > 0) {
				elemento.mensajeEliminarDetalles().then(r => {
					if (r.value) {

						elemento.setearRazonSocial(item, elemento);
					} else {
						elemento.modalRef.hide();
					}
				});
			} else {
				elemento.setearRazonSocial(item, elemento);
			}

		};
	}

	abrirCondiciones() {
		if (!this.model.idEntidad) { return; }
		let model = this.model;
		let elemento = this;
		let texto = "";
		if (this.model.nombreCondicionPago && !this.model.idCondicionPago && this.model.nombreCondicionPago.length > 0) {
			texto = this.model.nombreCondicionPago;
		}
		this.abrirModalBusqueda(CondicionComponent, texto);

		this.modalRef.content.abrirInfo = function (item) {

			if (elemento.getDetalles() && elemento.getDetalles().length > 0) {
				elemento.mensajeEliminarDetalles().then(r => {
					if (r.value) {
						elemento.inicioCargando();
						elemento.setearCondicion(item, elemento);
						elemento.recalcular(model);
					} else {
						elemento.modalRef.hide();

					}
				});
			} else {
				elemento.setearCondicion(item, elemento);
			}

		};
	}
	recalcular(model?) {
		if (!model) {
			model = this.model;
		}
		//	this.inicioCargando();

		this.service.cambiarCondicion(model, model.idCondicionPago).subscribe(r => {
			console.log(r);
			model.detalles = new Cotizacion().convertirRespuesta(r).detalles;
			this.actualizarDovs();

			this.finCargando();
		}, e => {
			this.finCargando();
		});



		/**/
		//for (let index = 0; index < this.model.detalles.length; index++) {

		//this.recalcularDetalle(index);
		//	}

	}


	private setearCondicion(item: any, elemento: CotizacionComponent) {
		this.descuentoCondicion = null;


		item.toString = function () { return this.descripcion; };
		elemento.model.nombreCondicionPago = item.descripcion;
		elemento.model.idCondicionPago = item.id;
		elemento.articulo = "";
		elemento.modalRef.hide();
		this.service.getRangoDescuentoCondicion(elemento.model.idCondicionPago).subscribe((d: DescuentoCondicion) => {
			if (d) {
				if (d.hasta > 0) {
					this.descuentoCondicion = "Limite de Desc. " + d.hasta + " %";
				} else {
					this.descuentoCondicion = "SIN DESCUENTO";
				}
			}
		});
	}

	setCondicion(item: { descripcion: string, id: number }) {
		item.toString = function () { return this.descripcion; };
		this.model.nombreCondicionPago = item.descripcion;
		this.model.idCondicionPago = item.id;
	}

	setRazonSocial(item: { razonSocial: string, id: number }) {
		item.toString = function () { return this.razonSocial; };
		this.model.razonSocial = item.razonSocial;
		this.model.idEntidad = item.id;
	}


	private setearRazonSocial(item: Entidad, elemento: this) {
		if (elemento.model.clonable !== undefined && !elemento.model.clonable) {
			elemento.modalRef.hide();
			return;
		}
		item.toString = function () { return this.razonSocial; };
		elemento.model.razonSocial = item.razonSocial;
		elemento.model.idEntidad = item.id;
		elemento.model.prospecto = item.prospecto ? item.prospecto : false;
		elemento.articulo = "";

		elemento.modalRef.hide();


	}

	mensajeEliminarDetalles(): Promise<SweetAlertResult> {
		return Swal.fire({
			title: "Atención",
			html: "Se recalcularan todos los artículos de la cotización",
			icon: 'warning',
			showCancelButton: true,
			showConfirmButton: true,
			cancelButtonText: "Cancelar",
			confirmButtonText: "Aceptar"
		});
	}

	agregarArticulo(r) {

		let detalleArticulo = {
			"articulo": Object.assign({}, this.articulo),
			"precio": r,
			"cantidad": this.articulo.fraccionVenta ? this.articulo.fraccionVenta : 1,
			"descuento": 0
		}
		let detalle: DetalleCotizacion = new DetalleCotizacion().convertir(detalleArticulo);

		detalle.noExiste = true;
		//this.model.detalles.push(detalle);
		this.articulo = "";
		this.editarDetalle(detalle, null, r.condicion);

		this.modalRef.hide();
	}


	editarDetalle(d, i, condicion) {
		this.inicioCargando();
		if (this.modo == "VER") {
			return;
		}
		if (!d.noExiste) {
			this.deslizarR(i);
		}
		if (!condicion) {
			condicion = 0;
		}
		this.service.getDescuentosArticulo(d, condicion).subscribe(descuentosArray => {
			this.descuentos = descuentosArray;
			this.finCargando()
			this.abrirModalDetalle(d);
		}, e => {
			this.finCargando();
			console.log(e);
		})

	}



	private abrirModalDetalle(d: DetalleCotizacion) {
		let detalle = Object.assign(new DetalleCotizacion(), d);
		this.modalAbajo.open(DetalleCotizacionComponent, {
			data: {
				detalle: detalle,
				real: d,
				descuentos: this.descuentos,
				multiplicador: d.fraccionVenta,
				detalles: this.model.detalles,
				observador: this
			}
		}).afterOpened().subscribe(r => {
			this.finCargando();
		});
	}

	deslizarL(i) {
		if (this.modo == "VER") { return }
		if (!this.model.detalles[i].deslizar) {
			document.getElementById("detalle" + i).classList.remove("animated");
			document.getElementById("detalle" + i).classList.remove("slideInLeft");
			document.getElementById("detalleB" + i).classList.remove("animated");
			document.getElementById("detalleB" + i).classList.remove("slideOutRight");
			document.getElementById("detalle" + i).classList.add("animated");
			document.getElementById("detalle" + i).classList.add("slideOutLeft");
			document.getElementById("detalleB" + i).classList.add("animated");
			document.getElementById("detalleB" + i).classList.add("slideInRight");
			this.model.detalles[i].deslizar = true;
		}
	}

	deslizarR(i) {
		if (this.modo == "VER") { return }
		if (this.model.detalles[i].deslizar) {
			document.getElementById("detalle" + i).classList.remove("animated");
			document.getElementById("detalle" + i).classList.remove("slideOutLeft");
			document.getElementById("detalleB" + i).classList.remove("animated");
			document.getElementById("detalleB" + i).classList.remove("slideInRight");
			document.getElementById("detalle" + i).classList.add("animated");
			document.getElementById("detalle" + i).classList.add("slideInLeft");
			document.getElementById("detalleB" + i).classList.add("animated");
			document.getElementById("detalleB" + i).classList.add("slideOutRight");
			this.model.detalles[i].deslizar = false;
		}
	}

	eliminar(d, i) {

		let e: EntidadMensaje = new EntidadMensaje({
			articulo: null
			, singular: "artículo",
			articuloPlural: null,
			plural: null,
			femenino: false
		});
		this.mensajes.mensajeEliminar(e).then(r => {
			if (r.value) {
				this.model.detalles.splice(i, 1);
				this.actualizarDovs();
			}
		});
		this.deslizarR(i);

	}

	abrirNuevoCliente() {
		if (this.getDetalles().length > 0) {
			this.mensajeEliminarDetalles().then(r => {
				if (r.value) {
					this.model.detalles = [];
					this.modalAbajo.open(NuevoClienteComponent, {
						data: {
							elemento: this
						}
					});
				} else {
					this.modalRef.hide();
				}
			});
		} else {
			this.modalAbajo.open(NuevoClienteComponent, {
				data: {
					elemento: this
				}
			});
		}

	}

	nuevoCliente(c) {

		c.id = -999;
		this.model.idEntidad = -999;
		this.model.razonSocial = c.razonSocial;
		this.model.cuit = c.cuitString;
		this.model.telefono = c.telefono;
	}


	//entidad botonMenu
	clickBotonMenu() {
		if (this.modo == "VER") { return };
		if (this.model.fechaCreacion) {
			this.model.fecha = this.model.fechaCreacion;
		} else {
			this.model.fecha = new Date();
		}
		this.model.importeTotal = this.getTotal();
		this.inicioCargando();
		if (this.model && this.model.id) { //actualizar
			this.service.actualizar(this.model).subscribe(r => {
				this.finCargando();
				this.router.navigate(["/inicio"]);
			}, e => {
				Swal.fire({
					allowOutsideClick: false,
					icon: 'error',
					title: 'Error',
					text: e
				});
			});
		} else {
			this.service.nuevo(this.model).subscribe(r => {

				this.finCargando();
				this.router.navigate(["/inicio"]);
			}, e => {
				console.log(e)
				this.finCargando();
			});
		}
	}

	botonDeshabilitado(): boolean {
		return this.model.detalles.length < 1;
	}

	getNombre(): string {
		return 'cotizacion';
	}


	cargarCotizacion(id: any) {
		this.inicioCargando();
		this.service.get(id).subscribe((r: Cotizacion) => {

			let model = new Cotizacion().convertirRespuesta(r);

			if (this.rutaActiva.snapshot.params.accion == "rehacer") {
				this.setRazonSocial({
					razonSocial: model.razonSocial,
					id: model.idEntidad
				});
				this.setCondicion({
					id: model.idCondicionPago,
					descripcion: model.nombreCondicionPago
				});

				for (let index = 0; index < model.detalles.length; index++) {
					let d = model.detalles[index];
					(<any>d).id = null;
					this.model.detalles.push(d)
				}
				model.detalles;
				this.recalcular();
				this.modo = MODOS.NUEVO;
				CabeceraComponent.titulo = "REHACER"
				Swal.fire({
					icon: "info",
					text: "Recuerde que puede cambiar la condición y/o la entidad"
				})
			}
			else
				if (model.estado.editable && this.rutaActiva.snapshot.params.accion != "ver") {
					this.model = model;
					this.modo = MODOS.EDITAR;
					CabeceraComponent.titulo = "EDITAR COTIZACION";
					if (this.rutaActiva.snapshot.params.accion == "recalcular") {
						this.recalcular();
						this.model.fechaVencimiento = undefined;
						this.model.fechaCreacion = new Date();
					} else {
						this.finCargando();
					}
				} else {
					this.model = model;
					CabeceraComponent.titulo = "VER COTIZACION";
					this.modo = MODOS.VER;
					this.finCargando();
				}

			//this.model = r;
		}, e => {
			console.log(e);
			this.finCargando();
		});


	}



	getDescripcionArticulo(d: DetalleCotizacion) {
		return d.descripcion;
	}

	getCodigoAlfanumerico(d: DetalleCotizacion) {
		return d.codigoAlfanumerico;
	}

	getMetodo(d: DetalleCotizacion) {
		return d.metodoPrecio;

	}

	getDescuento(d: DetalleCotizacion) {
		if (d.porcentajeDescuento > 0) {
			return 'x ' + d.porcentajeDescuento + "%";
		}
		return "";
	}


	observacionesBoton() {
		this.observacionesOcultar = !this.observacionesOcultar;
		if (!this.observacionesOcultar) {
			document.getElementById("observaciones").classList.add('animated');
			document.getElementById("observaciones").classList.add('bounceIn');
		} else {
			document.getElementById("observaciones").classList.remove('animated');
			document.getElementById("observaciones").classList.remove('bounceIn');
		}


	}
}


enum MODOS {
	NUEVO = "NUEVO", EDITAR = "EDITAR", VER = "VER"
}