import { environment } from 'src/environments/environment';
import { BotonIconoEstilo } from 'src/app/generico/formlys/boton-icono/boton-icono-estilo';
interface Generico {
	html;
	css;
}
export class Configuracion {

	static MODOS = { VER: 0, EDITAR: 1, NUEVO: 2 };

	static obtenerClaseIcono(tipo: string): BotonIconoEstilo {
		switch (tipo) {
			case "editar":
				return new BotonIconoEstilo("fa fa-edit", "btn btn-primary");
			case "eliminar":
				return new BotonIconoEstilo("fa fa-trash", "btn btn-danger");
			case "ver":
				return new BotonIconoEstilo("fa fa-search", "btn btn-info");
			default:
				return new BotonIconoEstilo("fa fa-fingerprint", "btn btn-warning");
		}
	}
}
