import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import { Observable } from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NotificacionService {

  constructor(private http: HttpClient) { }


  leerNotificaciones() : Observable<Notification[]> {
    return this.http.get<any>('/api/lessons')
      .pipe(
        map(res => res.notificaciones)
      )
  }

  buscarNotificacion(id:number) {
      return this.http.get<Notification>('/api/lessons/' + id);
  }


}
