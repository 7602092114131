import { Component, OnInit, Inject, ViewContainerRef } from '@angular/core';
import { AppService } from './services/app.service';

import { LogisoftStorage } from './generico/storage/logisoft-storage';
import {environment} from '../environments/environment';
import { Configuracion } from './generico/configuracion';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  title = 'pro-dashboard-angular';
  login = LogisoftStorage.isUsuario();

  constructor(private appService: AppService) {
   
  }

  getClasses() {
    const classes = {
      'pinned-sidebar': this.appService.getSidebarStat().isSidebarPinned,
      'toggeled-sidebar': this.appService.getSidebarStat().isSidebarToggeled
    };
    return classes;
  }

  toggleSidebar() {
    this.appService.toggleSidebar();
  }

  isUsuario(): boolean {
    return LogisoftStorage.isUsuario();
  }


  ngOnInit() {   
  }

}