import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class InfiniteService {
  fScroll(){
    if(this.pantalla){
      this.pantalla.fScroll();
    }
  };

  pantalla=null;
  constructor() { }
 
}
