import { Component, OnInit } from '@angular/core';
import { MenuItem } from './menu-item';
import { LogisoftStorage } from 'src/app/generico/storage/logisoft-storage';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Menu } from './menu';
import { AppService } from 'src/app/services/app.service';
import { environment } from 'src/environments/environment';
import { TablaComponent } from 'src/app/generico/tabla/tabla.component';


@Component({
	selector: 'app-sidebar',
	templateUrl: './sidebar.component.html',
	styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

	menues = [];


	constructor(public router: Router, private appService: AppService, private activate: ActivatedRoute) {
		this.setItems();
	}



	ngOnInit() {
	}

	setItems() {
		let items = [
			new MenuItem('Inicio', '/inicio', 'home'),
			new MenuItem('Mi Perfil', '/mi-perfil', 'user'),
			new MenuItem('Cotización', '/cotizacion', 'file-signature'),
			new MenuItem('Cotizaciones', '/cotizaciones-filtro', 'file-alt'),
			new MenuItem('Relevamiento', '/relevamiento', 'cash-register'),
			// new MenuItem('Tutorial', '/tutorial', 'cash-register')
		];
		this.menues = [new Menu("Dashboard", items)];
	}

	getNombreUsuario(): string {
		return LogisoftStorage.getNombreUsuario().replace("@hab.com.ar", "");
	}

	logout() {
		this.appService.toggleSidebar();
		LogisoftStorage.cerrarSesion();
		this.router.navigate(["login"]);
	}

	isBaseProduccion(): boolean {
		return (!environment.production && !environment.url.includes("localhost"));
	}

	getBD() {
		return environment.url;
	}

	irA(item: MenuItem) {
		if (item.funcionAntes) {
			item.funcionAntes();
		}
		this.appService.toggleSidebar();
		this.router.navigate([item.ruta]);
	}

	isSeleccionado(item: MenuItem) {

		return this.router.url == item.ruta;
	}


	getImagen() {
		LogisoftStorage.getImagenUsuario();
	}



}
