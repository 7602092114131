import { CargandoComponent } from "./cargando/cargando.component";
import Swal from 'sweetalert2';
import { LayoutComponent } from '../layout/layout/layout.component';
import { Injector } from '@angular/core';
import { InfiniteService } from '../services/infinite.service';

export abstract class PantallaCargando {
    cargando: number=0;
	paginaActual=null;
	scrollInfiniteService:InfiniteService;
	constructor(public injector:Injector){
		this.scrollInfiniteService=injector.get(InfiniteService);
		this.scrollInfiniteService.pantalla=null;
	}
	inicioCargando() {
		this.cargando++;
		if(this.cargando==1){
			CargandoComponent.mostrarCargando();			
        }        
		
	}

	finCargando(){		
		--this.cargando;
		if(this.cargando<0){
			this.cargando=0;
		}
		if(this.cargando==0){
			Swal.close();
		}
	}

	public set funcionScroll(fs){
		this.scrollInfiniteService.pantalla=fs;
	}

}
