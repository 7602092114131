import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, timeout } from 'rxjs/operators';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class BasicAuthInterceptorService implements HttpInterceptor {
  constructor(private router:Router){

  }
  
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      // add authorization header with basic auth credentials if available
      let currentUser = JSON.parse(localStorage.getItem('currentUser'));
      if (currentUser && currentUser.authdata) {
          request = request.clone({
              setHeaders: {
                  Authorization: `Basic ${currentUser.authdata}`
              }
          });
      }

	  return next.handle(request)
		//   .pipe(
        // timeout(5000),
		//   catchError(err => {
		// 	  console.log(err);
        //    /* location.reload();*/         
        //    Swal.fire({
        //      title:"Error",
        //      text:"Se ha producido un error, vuelva a intentarlo",
        //      icon:"error"
        //    })
          
        //    return throwError("Timeout has occurred");
        // }))
      
  }
}
