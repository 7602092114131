import { JsonPipe } from '@angular/common';
import { PantallaAtrasStorage } from '../boton-atras/pantalla-atras-storage';

export class LogisoftStorage {
  static getNoVolverAMostrarTutorial() {
	  let tutorial=localStorage.getItem('tutorial');
    if(tutorial){
		return JSON.parse(tutorial).noVolverAMostrar;
	}
	return false;
  }

  static setNoVolverAMostrarTutorial(b:boolean) {
	let tutorial:any=localStorage.getItem('tutorial');
  if(tutorial){
	  tutorial=JSON.parse(tutorial);
	  tutorial.noVolverAMostrar=b;
	 
  }else{
	  tutorial={noVolverAMostrar:b};
  }
  localStorage.setItem("tutorial",JSON.stringify(tutorial));
  
}

	static borrarPantallaAtras() {
		localStorage.removeItem('atras');
	}

	public static getPantallaAtras(): PantallaAtrasStorage {
		return JSON.parse(localStorage.getItem('atras'));
	}

	public static isAtras() {
		if (localStorage.getItem('atras')) {
			return true;
		}
		return false;
	}

	public static setPantallaAtras(pantalla: PantallaAtrasStorage) {
		localStorage.setItem('atras', JSON.stringify(pantalla));
	}

	public static isUsuario() {
		if (localStorage.getItem('currentUser')) {
			return true;
		}
		return false;
	}
	public static cerrarSesion() {
		localStorage.removeItem('currentUser');
	}

	public static getNombreUsuario(): string {
		if (!localStorage.getItem('currentUser')) { return ''; }
		return JSON.parse(localStorage.getItem('currentUser')).nombre;
	}

	public static getUsuario() {
		if (!localStorage.getItem('currentUser')) { return ''; }
		return JSON.parse(localStorage.getItem('currentUser'));
	}
	static isColores() {
		return false;
		//esto estaba hecho para mejorar la performance , ahora siempre busca en el backend
		if (LogisoftStorage.getColoresObject() ? ((new Date().getTime() - LogisoftStorage.getColoresObject().fecha) > 86000) : false) {
			sessionStorage.removeItem('colores');
			return false;
		}

		return LogisoftStorage.getColores() != null;
	}

	static getColores() {
		return sessionStorage.getItem('colores') ? JSON.parse(sessionStorage.getItem('colores')).colores : null;
	}

	static getColoresObject() {
		return sessionStorage.getItem('colores') ? JSON.parse(sessionStorage.getItem('colores')) : null;
	}

	static setColores(array) {
		let o = { fecha: new Date().getTime(), colores: array }
		sessionStorage.setItem('colores', JSON.stringify(o));
	}

	static getImagenUsuario() {
		if (localStorage.getItem('imageUser')) {
			console.log(localStorage.getItem('imageUser').toString())
			return localStorage.getItem('imageUser').toString();
		} else {
			return "assets/img/user.jpg"
		}
	}
}
