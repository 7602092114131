import { Component, OnInit, Inject, ChangeDetectorRef, ViewChild } from '@angular/core';
import { CotizacionesFiltroComponent } from '../cotizaciones-filtro.component';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { TablaComponent } from 'src/app/generico/tabla/tabla.component';
import { BsModalService, BsModalRef } from 'ngx-bootstrap';
import { CondicionComponent } from '../../condiciones/condicion/condicion.component';
import { EntidadComponent } from '../../entidades/entidad/entidad.component';
import { ListaCotizacionesComponent } from '../../lista-cotizaciones/lista-cotizaciones.component';

@Component({
  selector: 'app-filtros',
  templateUrl: './filtros.component.html',
  styleUrls: ['./filtros.component.scss']
})
export class FiltrosComponent implements OnInit {
  estados=[];
  model:any={};
  modalRef: BsModalRef;  
  elemento:CotizacionesFiltroComponent
  constructor(public cd:ChangeDetectorRef,public modalService: BsModalService,@Inject(MAT_BOTTOM_SHEET_DATA) public data: any, private _bottomSheetRef: MatBottomSheetRef<CotizacionesFiltroComponent>) { 
    this.estados=data.estados;
    this.model=data.filtros;
    this.elemento=data.elemento;
  }

  ngOnInit() {
   
  }
  cerrarModal(){
    this._bottomSheetRef.dismiss();
  }


  aceptar(){
    this.elemento.filtros=this.model;
    this.elemento.filtrosMostrar=this.obtenerFiltrosMostrar();
    this.elemento.actualizar();
    this.cerrarModal();
  }
  obtenerFiltrosMostrar(): any[] {
    let mostrar=[];
    if(this.model.estado){
      mostrar.push({items:["estado"],nombre:"Estado :"+this.model.estado.nombre})
    }  
    if(this.model.fechaDesde && this.model.fechaHasta){
      mostrar.push({items:["fechaDesde","fechaHasta"],nombre:"Entre "+this.model.fechaDesde+"-"+this.model.fechaHasta});
    }else{
      if(this.model.fechaDesde ){
        mostrar.push({items:["fechaDesde"],nombre:"Desde:"+this.model.fechaDesde})}
        if( this.model.fechaHasta){
          mostrar.push({items:["fechaHasta"],nombre:"Hasta:"+this.model.fechaDesde});}
    }
    if(this.model.condicion){
      mostrar.push({items:["condicion"],nombre:"Condición :"+this.model.condicion.descripcion})
    }
    if(this.model.razonSocial){
      mostrar.push({items:["razonSocial"],nombre:"Razón Social :"+this.model.razonSocial.razonSocial})
    }

    return mostrar;
  }

  cancelar(){
    this.cerrarModal();
  }

  changeEstado(e){
   
  }

  abrirCondiciones() {
	

		let elemento = this;
		let texto = "";
		if (this.model.condicionPago && this.model.condicionPago.length > 0) {
			texto = this.model.condicionPago;
		}
		this.abrirModalBusqueda(CondicionComponent, texto);

		this.modalRef.content.abrirInfo = function (item) {
      item.toString=function(){return this.descripcion}
      elemento.setDato("condicion",item);   
      elemento.modalRef.hide();
		};
  }
  abrirEntidades() {

		let elemento = this;
		let texto = "";
		if (this.model.razonSocial && !this.model.idEntidad && this.model.razonSocial.length > 0) {
			texto = this.model.razonSocial;

		}
		this.abrirModalBusqueda(EntidadComponent, texto);

		this.modalRef.content.abrirInfo = function (item) {
      item.toString=function(){return this.razonSocial}      
      elemento.setDato("razonSocial",item);   
      elemento.modalRef.hide();     
		};
  }
  

  setDato(prop:string,item: any) {
    this.model[prop]=item;
    this.cd.detectChanges();
  }

 
  public abrirModalBusqueda(x, texto) {
		TablaComponent.active = 0;
		this.modalRef = this.modalService.show(x);
		this.modalRef.content.busqueda.texto = texto;
		this.modalRef.content.textoBuscar = texto;
		this.modalRef.content.setPaginacion(5);
		this.modalRef.content.iconoInfo = "fas fa-angle-right fa-lg";
		this.modalRef.content.tituloCentrado = true;
		this.modalRef.content.elemento = this;
		TablaComponent.active = 1;
  }
  

  compararEstados(e1,e2){
   

    if(!e1 || !e2){
      return false;
    }
    return e1.id===e2.id;
  }
}
