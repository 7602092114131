import { Component, OnInit, Injector, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { EditarComponent } from 'src/app/generico/editar/editar.component';
import { Cotizacion } from '../cotizacion';
import { CotizacionService } from '../cotizacion.service';
import { ServiciosConfig } from 'src/app/services/generico/servicios-config';
import { ActivatedRoute, Router } from '@angular/router';
import { Configuracion } from 'src/app/generico/configuracion';

import Swal from 'sweetalert2'
import { CargandoComponent } from '../../cargando/cargando.component';
import { CortevaService } from 'src/app/services/corteva.service';
import { MensajesService } from 'src/app/generico/mensajes/mensajes.service';

@Component({
	selector: 'app-cotizacion-editar',
	templateUrl: './cotizacion-editar.component.html',
	styleUrls: ['./cotizacion-editar.component.scss']
})
export class CotizacionEditarComponent extends EditarComponent<Cotizacion> implements OnInit, AfterViewInit {
	mostrarPrev = true;
	cd: ChangeDetectorRef;
	detalles = [];
	DOVs = [];
	constructor(public router: Router, public service: CotizacionService, public injector: Injector, public rutaActiva: ActivatedRoute) {
		super(service, injector, rutaActiva);
		this.serviciosConfig = ServiciosConfig;
		this.cd = injector.get(ChangeDetectorRef);
	}
	ngAfterViewInit(): void {
		let col: HTMLCollection = document.getElementsByTagName("mat-label");
		for (let i = 0; i < col.length; i++) {
			(<any>col[i]).style.color = "white";
		}
		if (this.modo == Configuracion.MODOS.NUEVO) {
			this.model.fecha = new Date();
			this.cd.detectChanges();
		}
	}

	ngOnInit() {
		super.ngOnInit();
		(<any>document.getElementsByClassName("mat-horizontal-stepper-header-container")[0]).style.visibility = "hidden";
		(<any>document.getElementsByClassName("mat-horizontal-stepper-header-container")[0]).style.height = "3em";
	}

	colorTarjeta() {
		return {
			"tarjeta-verde": this.activedStep == 0 || this.activedStep == 2,
			"tarjeta-naranja": this.activedStep == 1
		};
	}

	submit() {
		(<CotizacionService>this.service).getPrecio(this.model.condicion.id, 2, this.model.articulo.id, this.model.razonSocial.id).subscribe(
			(r: any) => {
				this.model.precio = r.precioVenta;
				this.detalles.push(
					{
						"articulo": this.model.articulo,
						"precio": r,
						"cantidad": 1
					}
				)
				this.model.articulo = null;
				//this.form.patchValue(this.model);
				//this.form.reset(this.model);
			}, (e => {
				Swal.fire({
					allowOutsideClick: false,
					icon: 'error',
					title: 'Error',
					text: e
				});

			})
		)
	}



}
