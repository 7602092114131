import { Component, Inject, Injector, OnInit } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import * as _ from 'lodash';
import { MensajesService } from 'src/app/generico/mensajes/mensajes.service';
import Swal from 'sweetalert2';
import { PantallaCargando } from '../../pantalla-cargando';
import { RelevamientoPrecio } from '../relevamiento-precio';
import { RelevamientoPrecioService } from '../relevamiento-precio.service';

@Component({
	selector: 'app-subir-archivo',
	templateUrl: './subir-archivo.component.html',
	styleUrls: ['./subir-archivo.component.scss']
})
export class SubirArchivoComponent  extends PantallaCargando implements OnInit {


	imageError: string;
	nombreArchivo: string;
	cardImageBase64: string;

	constructor(public injector:Injector,@Inject(MAT_BOTTOM_SHEET_DATA) public data: any, private _bottomSheetRef: MatBottomSheetRef<RelevamientoPrecio>,private servicioRelevamiento : RelevamientoPrecioService) { 
		super(injector);
	}

	ngOnInit() {
	}

	fileChangeEvent(fileInput: any) {
		console.log(fileInput)
		this.imageError = null;
		if (fileInput.target.files && fileInput.target.files[0]) {
			// Size Filter Bytes
			const max_size = 20971520;
			const allowed_types = ['image/png', 'image/jpeg', 'application/msword',
				'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
				'image/jpg',
				'application/pdf',
				'application/vnd.ms-excel',
				'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
			const max_height = 15200;
			const max_width = 25600;

			if (fileInput.target.files[0].size > max_size) {
				this.imageError =
					'Maximum size allowed is ' + max_size / 1000 + 'Mb';

				return false;
			}

			if (!_.includes(allowed_types, fileInput.target.files[0].type)) {
				this.imageError = 'Archivo Incompatible';
				this.nombreArchivo=null;
				this.cardImageBase64=null;
				return false;
			}
			this.nombreArchivo = fileInput.target.files[0].name;
			const reader = new FileReader();
			reader.onload = (e: any) => {
				const imgBase64Path = e.target.result;
				this.cardImageBase64 = imgBase64Path;
			};

			reader.readAsDataURL(fileInput.target.files[0]);
		}
	}

	subirArchivo() {
		console.log(this.data)
		this.inicioCargando();
		var particion = this.cardImageBase64.split(';');

		let archivo = {
			objeto: this.data.data,
			archivo: particion[1].split(',')[1],
			nombreArchivo : this.nombreArchivo
		}
		this.servicioRelevamiento.subirArchivo(archivo).subscribe(r=>{
			this.data.observador.actualizar();
			this.finCargando();
			this.cancelar();
		},e=>{
			MensajesService.mensajeError("Error de subida");
			
			this.finCargando();
			this.cancelar();
		});
	}

	cancelar() {
		this._bottomSheetRef.dismiss();
	}

}
