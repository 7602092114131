import { GenericoService } from 'src/app/generico/servicios/generico.service';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { OrigenRelavamiento } from './origen-relavamiento';
import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class OrigenRelavamientoService extends GenericoService<OrigenRelavamiento> {

	urlObjeto = 'origen-relevamientos';

	constructor(public http: HttpClient) {
		super(http);
		this.url = environment.url;
	}

	//override  
	getPaginaServidorBusqueda(pageIndex: number, pageSize: number, parametros) {
		parametros.push(["sort", "nombre,ASC"]);
		parametros.push(["pagination", true]);
		return super.getPaginaServidorBusqueda(pageIndex, pageSize, parametros);
	}


}
