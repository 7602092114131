export class EntidadBackend<T> {

 

    convertirRespuesta(r: any) {
        for (const prop in r) {        
            this.conversion(prop, r);         
         }
        return this;
     }

     conversion(prop: string, r: any) {         
        this[prop] = r[prop];        
    }
   
}
