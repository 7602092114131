import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { Buscador } from './buscador';

@Component({
  selector: 'app-busqueda',
  templateUrl: './busqueda.component.html',
  styleUrls: ['./busqueda.component.scss']
})
export class BusquedaComponent implements OnInit {
 
  
  @Input()
  buscador:Buscador; 
  @Input()
  texto:string="";
  habilitado=true;
  constructor() { }

  ngOnInit() {
  }

  limpiar(){
    this.texto="";
    this.buscar(this.texto);
    this.habilitado=true;
  }

  buscar(texto){
    //this.habilitado=false;
   
      this.buscador.buscar(texto); 
    
       
  }

  onKey(evt){
    let longitud=this.texto.length.valueOf();
    setTimeout(() => {      
      this.buscarEspera(longitud);
     }, 450);
  }

  buscarEspera(l) {
   if(this.texto.length==l){
     this.buscar(this.texto);
   }
  }



}
