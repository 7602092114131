import { AppService } from './../../services/app.service';
import { Component, OnInit } from '@angular/core';
import { LogisoftStorage } from 'src/app/generico/storage/logisoft-storage';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

	version;
  
  constructor(private appService: AppService) { }
  isCollapsed = true;
	ngOnInit() {
		this.version = this.appService.getVersion().subscribe(version => this.version = version);
  }

  toggleSidebarPin() {
    this.appService.toggleSidebarPin();
  }
  toggleSidebar() {
    this.appService.toggleSidebar();
  }

  isUsuario():boolean{
    return LogisoftStorage.isUsuario();
  }

}
