import { Estado } from './../inicio/estado';
import { NumberValueAccessor } from '@angular/forms/src/directives';
import { DetalleCotizacion } from './detalle-cotizacion';
import { EntidadBackend } from '../entidad-backend';
import { LogisoftStorage } from 'src/app/generico/storage/logisoft-storage';
import { Color } from './color';

export class Cotizacion extends EntidadBackend<Cotizacion> {
	cantBolsasDOV: number = 0;
	importeDOV: number = 0;
	importeDOA: number = 0;
	importeDPK: number = 0;
	importeDCA: number = 0;
	numero: number;
	numeroHijo: number;
	numeroCompleto: string;
	fecha: Date;
	fechaCreacion: Date;
	fechaVencimiento: Date;
	fechaCompartida: Date;
	importeTotal: number;
	idMoneda: number = 2;
	nombreMoneda: string;
	idCondicionPago: number;
	nombreCondicionPago: string;
	idEntidad: number;
	razonSocial: string;
	telefono: string;
	cuit: string;
	idVendedor: number;
	nombreVendedor: string;
	color: Color;
	observaciones: string;
	detalles: DetalleCotizacion[] = [];
	id: number;
	margenTotal: number;
	hash: string;
	estado: Estado;
	clonable: boolean;
	importeDCON: number;
	dovunitario: number=0;
	prospecto:boolean=false;

	getImporteDescuentosFinanciado() {
		return this.detalles.reduce(function (accumulator, currentValue) {
			return accumulator + ((currentValue.getImporteDOAFinanciado() + currentValue.getImporteDCAFinanciado() + currentValue.getImporteDPKFinanciado()) * currentValue.cantidad);
		}, 0)
	}
	getImporteDOVFinanciado() {
		let elem=this;
		return this.detalles.reduce(function (accumulator, d) {		
			
			return accumulator + (d.dov?((1+d.interesAUsar)*elem.dovunitario*d.cantidad):0);
		}, 0)
		//return this.detalles.length > 0 ? (this.importeDOV * (1 + this.detalles[0].interesAUsar)) : 0;
	}

	getImporteDCON() {
		let importeDCON = 0;
		this.detalles.forEach(d => {
			if (d.dcon) {
				let subTotalLinea = d.getTotalLinea();		
				subTotalLinea = subTotalLinea - ((this.dovunitario * (1 + d.interesAUsar)) * d.cantidad);
				let descuentoLineaCondicionPago = subTotalLinea * (d.ddcon / 100);
				importeDCON = importeDCON + descuentoLineaCondicionPago;
			}
		});
		return importeDCON;
	}

	getImporteDOAFinanciado() {
		
		return this.detalles.reduce(function (accumulator, d) {		
			return accumulator + (1+d.interesAUsar)*d.importeDOA*d.cantidad;
		}, 0)
		
	}

	getImporteDPKFinanciado() {
		return this.detalles.reduce(function (accumulator, d) {		
			return accumulator + (1+d.interesAUsar)*d.importeDPK*d.cantidad;
		}, 0)
	}

	getImporteDCAFinanciado() {
		return this.detalles.reduce(function (accumulator, d) {		
			return accumulator + (1+d.interesAUsar)*d.importeDCA*d.cantidad;
		}, 0)
	}
	//entidad backend , esto se usa para convertir el objeto que viene del backend
	conversion(prop: string, r: any) {
		if (prop == "usuario") {
			return;
		}
		if (prop == "detalles") {
			r.detalles.forEach(d => {
				this.detalles.push(new DetalleCotizacion().convertirRespuesta(d));
			});
		} else {

			this[prop] = r[prop];
		}

	}

	static conversionArray(array) {
		for (let index = 0; index < array.length; index++) {
			array[index] = new Cotizacion().convertirRespuesta(array[index]);
		}
		return array;
	}
	///fin entidad backend

	getColor() {
		for (let i = 0; i < LogisoftStorage.getColores().length; i++) {
			let c: Color = LogisoftStorage.getColores()[i];
			if (c.limiteDesde <= this.margenTotal && c.limiteHasta >= this.margenTotal) {
				return c.color;
			}
		}
	}

	getColorFondo() {
		return this.color.color;
	}

	getVencimiento() {
		let resta = 0;

		if (this.fechaVencimiento && this.fechaCreacion) {
			resta = new Date(this.fechaVencimiento).getTime() - new Date().getTime();
		}
		return Math.ceil(resta / (1000 * 60 * 60 * 24));
	}

	getVencimientoText() {
		if (this.fechaVencimiento == null) {
			return "";
		}
		let v = Number(this.getVencimiento());
		if (v < 0) {
			v = v * -1;
			return "Vencido hace " + v + " días";
		}

		if (v == 0) {
			return "Vence hoy";
		}

		return "Vence en " + v + " días";
	}

	isVencimiento(){
		return this.estado.codestado=="ENVIADO" || this.estado.codestado=="VENCIDA";
	}


	getColorVencimiento() {
		let v = Number(this.getVencimiento());
		if (v < 0) {
			return "#B80C0C";
		}

		if (v == 0) {
			return "#FFD600";
		}

		return "#0C740C";
	}

	getEstado() {
		return this.estado;

	}

	getNumeroHijo() {
		return this.numeroHijo;
	}



}



