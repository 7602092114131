import { environment } from './../../../environments/environment';
import { GenericoService } from './../../generico/servicios/generico.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RelevamientoPrecio } from './relevamiento-precio';

@Injectable({
	providedIn: 'root'
})
export class RelevamientoPrecioService extends GenericoService<RelevamientoPrecio> {

	urlObjeto = 'relevamientos';

	constructor(public http: HttpClient) {
		super(http);
		this.url = environment.url;
	}

	//override
	getAll() {
		let url = this.url + "relevamientos?sort=id,DESC&page=0&size=30";
		return this.http.get(url);
	}


	getCondicionesRelevamientos() {
		return this.http.get(this.url + "condicion-relevamientos");
	}

	subirArchivo(objeto: any) {
		console.log(objeto)
		return this.http.post(this.url + "relevamientos/" + objeto.objeto.id + "/archivo", objeto);
	}


}
