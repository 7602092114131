import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Configuracion } from '../../generico/configuracion';
import { LogisoftStorage } from 'src/app/generico/storage/logisoft-storage';
import { environment } from 'src/environments/environment';

 

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor(private http: HttpClient) { }

  login(username: string, password: string) {
    let a= window.btoa(username + ':' + password);
    let header=new HttpHeaders({'Authorization': 'Basic ' +a});            
         return this.http.get<any>(environment.url+"usuarios/actual/",{headers:header})
          .pipe(map(user => {
              // login successful if there's a user in the response
              if (user) {
                  // store user details and basic auth credentials in local storage 
                  // to keep user logged in between page refreshes
                  user.authdata = window.btoa(username + ':' + password);
                  localStorage.setItem('currentUser', JSON.stringify(user));
                  
                                    this.http.get(environment.url+"usuarios/"+user.id+"/imagen").subscribe((ri:any)=>{
                                                                    
                    localStorage.setItem('imageUser',ri);
                  },e=>{
                    localStorage.removeItem('imageUser');
                  })
              }

              return user;
          }));
  }

  logout() {
      // remove user from local storage to log user out
      LogisoftStorage.cerrarSesion();
      
     
    
  }

 

}
