import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ElementoTablaComponent } from '../elemento-tabla/elemento-tabla.component';

@Component({
  selector: 'app-icono',
  templateUrl: './icono.component.html',
  styleUrls: ['./icono.component.scss']
})
export class IconoComponent extends ElementoTablaComponent implements OnInit,AfterViewInit  {
  
  constructor(public cd:ChangeDetectorRef) {
    super(cd);
  }

  ngAfterViewInit(): void {    
  }

  ngOnInit() {   
       
  }

 

 

}
