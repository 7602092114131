import { Component, OnInit } from '@angular/core';
import { LogisoftStorage } from 'src/app/generico/storage/logisoft-storage';
import { Router } from '@angular/router';

@Component({
  selector: 'app-panel-boton-atras',
  templateUrl: './panel-boton-atras.component.html',
  styleUrls: ['./panel-boton-atras.component.scss']
})
export class PanelBotonAtrasComponent implements OnInit {

  constructor(private router:Router) { }

  ngOnInit() {
  }

  existePantallaAtras(){
    return LogisoftStorage.isAtras();
  }

  atras(){
    this.router.navigate([LogisoftStorage.getPantallaAtras().url]);
  }

}
