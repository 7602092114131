import { Component, OnInit, Input } from '@angular/core';
import { AccionesGenerales } from './acciones-generales';

@Component({
  selector: 'app-acciones',
  templateUrl: './acciones.component.html',
  styleUrls: ['./acciones.component.scss']
})
export class AccionesComponent implements OnInit {
  @Input()
  componente:AccionesGenerales; 
  constructor() { }

  ngOnInit() {
  }

  nuevo(){
    this.componente.nuevo();
  }
}
