import { Component, OnInit, ViewChild, AfterViewInit, TemplateRef, Injector, EventEmitter, ChangeDetectorRef, InjectionToken, ElementRef } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';

import { GenericoService } from 'src/app/generico/servicios/generico.service';
import { Buscador } from '../formlys/busqueda/buscador';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ListaComponent } from '../lista/lista.component';
import { ModalTablaComponent } from './modal-tabla/modal-tabla.component';
import { AccionesGenerales } from '../formlys/acciones/acciones-generales';
import { Router } from '@angular/router';
import { MensajesService, TipoMensaje } from 'src/app/generico/mensajes/mensajes.service';
import { EntidadMensaje } from 'src/app/generico/mensajes/entidad-mensaje';
import { PantallaAtras } from '../boton-atras/pantalla-atras';
import { LogisoftStorage } from 'src/app/generico/storage/logisoft-storage';
import { BusquedaComponent } from '../formlys/busqueda/busqueda.component';
import { Pagina } from '../paginacion/pagina';
import { Paginador } from '../paginacion/paginador';

@Component({
  selector: 'app-tabla',
  templateUrl: 'tabla.component.html',
  styleUrls: ['tabla.component.scss']
})
export class TablaComponent<T> implements OnInit, AfterViewInit, Buscador,AccionesGenerales,PantallaAtras {
  public static active=1;
  modalRef: BsModalRef;
  servicioMensajes:MensajesService;
  cd:ChangeDetectorRef;
  accionNuevo=true;  
  constructor(public injector: Injector,public service: GenericoService<T>, public modalService: BsModalService,public router:Router) {
    this.columnas = this.service.getCampos().camposListado;
    this.acciones=this.service.getCampos().accionesGenerales;
    if(TablaComponent.active==1){ this.inicioConstructor();}
    this.servicioMensajes=this.injector.get<MensajesService>(MensajesService);
    this.cd=this.injector.get(ChangeDetectorRef);
    
  }
 
  tituloCentrado=false;
  iconoInfo="fa fa-info-circle";
  cargando = true;
  data: T[] = [];
  titulo = '';
  columnas = [];
  acciones=[];
  opcionesPaginacion = {
    pageSizeOptions: [5, 10, 25, 100],
    length: 0,
    pageSize: 10
  };
  textoBuscar: string = null;
  @ViewChild(MatPaginator)
  paginador: MatPaginator;
  @ViewChild(BusquedaComponent)
  busqueda :BusquedaComponent;

  inicioConstructor() {
    if(this.verificarPantallaAtras()){return;}
    this.buscar(this.textoBuscar);
    /*this.service.getPaginaServidor(0, this.opcionesPaginacion.pageSize).subscribe((r: Pagina<T>) => {
      this.data = r.content;
      this.opcionesPaginacion.length = r.totalElements;
      this.cargando = false;
    }, error => {
      console.log(error);
    });
   */
  }
  verificarPantallaAtras() :boolean{
    if(LogisoftStorage.isAtras()){ 
      if(LogisoftStorage.getPantallaAtras().url==this.router.url){
        return true;
      }
    }
    return false;
  }


 
  ngOnInit() {
  
  }
  ngAfterViewInit(): void {
    if(this.verificarPantallaAtras()){
      this.getParametrosAtras();    
    }    
    this.paginador.page.subscribe(this.cambioDePagina());
   
   
  }
  cambioDePagina(): any {
    return (r: Paginador) => {
      this.paginacionConBusqueda(r);
      
    };
  }



  paginacionConBusqueda(r: Paginador) {
    this.cargando = true;
    let parametros = this.getParametros();
    this.service.getPaginaServidorBusqueda(r.pageIndex, r.pageSize, parametros).subscribe((r: Pagina<T>) => {
      this.opcionesPaginacion.length = r.totalElements;
      this.data = this.convertirRespuesta(r.content);
      this.cargando = false;
      document.getElementById('inputBuscar').focus();
     
    }, error => {
      console.log(error);
    });
  }
  convertirRespuesta(content: T[]): T[] {
    return content;
  }

  getParametros() {
    let parametros = [];
    if (!this.textoBuscar || this.textoBuscar.length == 0) { return parametros; }
    this.columnas.forEach(col => {
      if (!col.propBuscar) { col.propBuscar = col.prop; }
      parametros.push([col.propBuscar, this.textoBuscar]);
    });
    return parametros;
  }


  //para "suscribirse como observador"
  getThis() {
    return this;
  }

  //interface buscar
  buscar(texto: string) {   
    this.textoBuscar = texto;
    this.paginador.pageIndex = 0;
    this.paginacionConBusqueda(<Paginador><unknown>this.paginador);
  }


  abrirInfo(item) {
    this.modalRef = this.modalService.show(ModalTablaComponent);    
    this.modalRef.content.item=item;
    this.modalRef.content.columnas=this.columnas;
    this.modalRef.content.acciones=this.acciones;
    this.modalRef.content.observador=this;
  }

//interface acciones generales
  nuevo() {
    this.setParametrosAtras();
    this.router.navigate([this.router.url+"/editar"]);
  }

  editar(id: any) {
    this.setParametrosAtras();
    this.router.navigate([this.router.url+"/editar/"+id]);
  }
  eliminar(id) {
    this.servicioMensajes.mostrarMensaje(TipoMensaje.ELIMINAR,new EntidadMensaje(this.service.getCampos()
    .mensaje)).then((result) => {
      if (result.value) {
        this.service.delete(id).subscribe(r=>{
          this.servicioMensajes.mostrarMensaje(TipoMensaje.EXITO_ELIMINAR,new EntidadMensaje(this.service.getCampos()
          .mensaje));
          this.paginacionConBusqueda(<any>this.paginador);
        },e=>{
          this.servicioMensajes.mostrarMensaje(TipoMensaje.ERROR,e);
        });        
      }
    }); 
  }

  ver(id) {
    this.setParametrosAtras();
    this.router.navigate([this.router.url+"/editar/"+id+"/ver"]);
  }

  //fin interface acciones generales


  //interface pantallaAtras
  setParametrosAtras() {
    let parametros={
      paginador:this.convertirPaginador(this.paginador),      
      busqueda:this.textoBuscar}      
      LogisoftStorage.setPantallaAtras({
        url:this.router.url,
        parametros:parametros
      });
  }
  convertirPaginador(paginador:MatPaginator):Paginador {
    return {
      length: paginador.length,
      pageIndex: paginador.pageIndex,
      pageSize: paginador.pageSize,
      previousPageIndex: 0
    };
  }
  getParametrosAtras() {    
    let pantallaAtras=LogisoftStorage.getPantallaAtras();
    this.textoBuscar=pantallaAtras.parametros.busqueda;
    if(this.textoBuscar && this.textoBuscar.length>0){
      //seteo el texto en el buscador
      this.busqueda.texto=this.textoBuscar;
      this.busqueda.habilitado=false;
    }   
    let paginador=pantallaAtras.parametros.paginador;    
    this.paginador.length=paginador.length;
    this.paginador.pageIndex=paginador.pageIndex;
    this.paginador.pageSize=paginador.pageSize;
    this.cd.detectChanges();
    this.paginacionConBusqueda(<any>this.paginador);
    LogisoftStorage.borrarPantallaAtras();
  }
  //fin interface pantallaAtras


  setPaginacion(x:number){
    this.opcionesPaginacion.pageSize=x;
    this.paginador.pageSize=x;
    this.inicioConstructor();
  }
}
