
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { LoginComponent } from './pages/login/login.component';
import { ArticulosComponent } from './pages/articulos/articulos.component';
import { AuthGuardService } from './services/autenticacion/auth-guard.service';
import { InicioComponent } from './pages/inicio/inicio.component';
import { CotizacionComponent } from './pages/cotizacion/cotizacion.component';
import { LayoutComponent } from './layout/layout/layout.component';
import { InformarArticulosComponent } from './pages/informar-articulos/informar-articulos.component';
import { MiPerfilComponent } from './pages/mi-perfil/mi-perfil.component';
import { CotizacionesFiltroComponent } from './pages/cotizaciones-filtro/cotizaciones-filtro.component';
import { EstadoCotizadorService } from './services/estado-cotizador.service';
import { TutorialComponent } from './pages/tutorial/tutorial.component';

const routes: Routes = [
  {path: '',   redirectTo: '/inicio', pathMatch: 'full'},
  {
    path: '',
    component: LayoutComponent,
    children: [
      {path: 'dashboard', component: DashboardComponent},  
      {path: 'cotizacion', component: CotizacionComponent,canActivate:[EstadoCotizadorService]}, 
      {path: 'cotizacion/:id', component: CotizacionComponent,canActivate:[EstadoCotizadorService]},
      {path: 'cotizacion/:accion/:id', component: CotizacionComponent,canActivate:[EstadoCotizadorService]},
      {path:'inicio',component:InicioComponent}, 
      {path:'relevamiento',component:InformarArticulosComponent},
      {path: 'mi-perfil', component: MiPerfilComponent}, 
      {path: 'cotizaciones-filtro', component: CotizacionesFiltroComponent}, 
    ],
    canActivate:[AuthGuardService]
  },  
  {path: 'login', component: LoginComponent},
  {path: 'tutorial', component: TutorialComponent},
  {path: '**',   redirectTo: '/inicio', pathMatch: 'full'}
  ];


@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash:true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
