import { CargandoComponent } from './../../cargando/cargando.component';
import { RelevamientoPrecioService } from './../relevamiento-precio.service';
import { Component, OnInit, Inject } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { InformarArticulosComponent } from '../informar-articulos.component';
import Swal, { SweetAlertResult } from 'sweetalert2';
import { LogisoftStorage } from 'src/app/generico/storage/logisoft-storage';

@Component({
	selector: 'app-detalle-relevamiento',
	templateUrl: './detalle-relevamiento.component.html',
	styleUrls: ['./detalle-relevamiento.component.scss']
})
export class DetalleRelevamientoComponent implements OnInit {
	valido = false;
	mostrarObservaciones = false;
	constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: any, private _bottomSheetRef: MatBottomSheetRef<InformarArticulosComponent>,
		private _servicio: RelevamientoPrecioService) { }

	ngOnInit() {
		this.validar();
	}

	getDetalle() {
		return this.data.detalle;
	}

	getDescripcion() {
		return this.getDetalle().articulo.descripcion;
	}

	getCodigo() {
		return this.getDetalle().articulo.codigoAlfanumerico;
	}

	aceptar() {
		let datos: any = {}
		datos.cantidad = this.data.detalle.cantidad;
		datos.precio = this.data.detalle.precio;
		datos.idArticulo = this.data.detalle.articulo.id;
		datos.descripcion = this.data.detalle.articulo.descripcion;
		datos.codigoAlfanumerico = this.data.detalle.articulo.codigoAlfanumerico;
		datos.origen = this.data.detalle.origen;
		datos.condicion = this.data.detalle.condicion;
		datos.idVendedor = LogisoftStorage.getUsuario().id;
		datos.nombreVendedor = LogisoftStorage.getNombreUsuario();
		datos.observaciones = this.data.detalle.observaciones;
		CargandoComponent.mostrarCargando();
		this._servicio.nuevo(datos).subscribe(r => {
			this.data.elemento.actualizar();
			Swal.close();

			this._bottomSheetRef.dismiss();
		}, e => {
			Swal.close();
			console.log(e)
		});

	}

	cancelar() {
		this._bottomSheetRef.dismiss();
	}

	cambioCantidad(cantidad) {
		//para hacer la validacion en tiempo real, el validator presentaba fallas
		this.validar();
	}
	validar() {
		this.valido = this.data.detalle.articulo.descripcion && this.data.detalle.cantidad > 0 && this.data.detalle.precio > 0 && this.data.detalle.origen;
	}

	cambioPrecio(precio) {
		//para hacer la validacion en tiempo real, el validator presentaba fallas
		//	this.valido=(cantidad && (cantidad % this.multiplicador) == 0);	
		this.validar();
	}


	cambioOrigen(origen) {
		this.validar();
	}

	cambioCondicion(cond) {
		this.validar();
	}

	getPrecioIndividual() {
		return this.data.detalle.precio / this.data.detalle.cantidad;
	}


	getPantalla(): InformarArticulosComponent {
		return this.data.elemento;
	}
}
