import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TutorialService {

  constructor(private http:HttpClient) { }

  getTutorial():Observable<ItemTutorial[]>{
    return <Observable<ItemTutorial[]>>this.http.get('assets/json/tutorial.json');
  }

}


export interface ItemTutorial{
  descripcion:string;
  imagen:string;
  orden:number;
}