import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Configuracion } from '../configuracion';
import { Observable } from 'rxjs';

import { Servicio } from './servicio';
import { Pagina } from 'src/app/generico/paginacion/pagina';

@Injectable({
	providedIn: 'root'
})
export class GenericoService<T> implements Servicio<T> {

	model: any;
	size = 10;
	url: string;
	urlObjeto = '';
	json: any = {};

	constructor(public http: HttpClient) { }

	get(id): Observable<any> {
		return this.http.get(this.getUrl() + "/" + id);
	}

	getAll() {
		return this.http.get(this.getUrl());
	}

	getPagina(): Observable<Pagina<T>> {
		return this.getPaginaServidor(0, this.size);
	}

	getPaginaServidor(pageIndex: number, pageSize: number): Observable<Pagina<T>> {
		const params = new HttpParams()
			.set('page', pageIndex.toString())
			.set('pagination', 'true')
			.set('size', pageSize.toString())
			.set('sort', 'id,asc');
		return <Observable<Pagina<T>>>this.http.get(this.getUrl(), { params });
	}

	getPaginaServidorBusqueda(pageIndex: number, pageSize: number, parametros) {
		let params = new HttpParams()
			.set('page', pageIndex.toString())
			.set('pagination', 'true')
			.set('size', pageSize.toString());

		for (let i = 0; i < parametros.length; i++) {
			const p = parametros[i];
			params = params.append(p[0], p[1]);
		}
		return <Observable<Pagina<T>>>this.http.get(this.getUrl(), { params });
	}
	getUrl(): string {
		return this.url + this.urlObjeto;
	}

	getCampos(): any {
		return this.json;
	}

	nuevo(objeto: T) {
		return this.http.post(this.getUrl(), objeto);
	}

	delete(id: any) {
		return this.http.delete(this.getUrl() + "/" + id);
	}

}
