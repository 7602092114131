

export class MenuItem {
    nombre: string;
    icono: string;
    ruta: string;
    funcionAntes:Function;

    constructor(nombre: string, ruta: string, icono: string,funcion?:Function) {
        this.nombre = nombre;
        this.ruta = ruta;
        this.icono = icono;
        this.funcionAntes=funcion?funcion:null;
    }
    
}
