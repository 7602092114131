import { Component, OnInit, HostListener } from '@angular/core';
import { AppService } from 'src/app/services/app.service';
import { LogisoftStorage } from 'src/app/generico/storage/logisoft-storage';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { InfiniteService } from 'src/app/services/infinite.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {

  title = 'pro-dashboard-angular';
  login = LogisoftStorage.isUsuario();
  static funcionScroll=function(){};
  
  @HostListener('window:hashchange', ['$event'])
  hashChangeHandler(e) {
    console.log(e);
    window.location.hash = "dontgoback";
  }
  constructor(private appService: AppService,private infiniteScroll:InfiniteService) {
    
  }

  getClasses() {
    const classes = {
      'pinned-sidebar': this.appService.getSidebarStat().isSidebarPinned,
      'toggeled-sidebar': this.appService.getSidebarStat().isSidebarToggeled
    };
    return classes;
  }

  toggleSidebar() {
    this.appService.toggleSidebar();
  }

  isUsuario(): boolean {
    return LogisoftStorage.isUsuario();
  }


  ngOnInit() {   
  }

  @HostListener('scroll', ['$event'])
 eventoScroll(){
   this.infiniteScroll.fScroll();
   }
   
  }

 

