import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import Swal, { SweetAlertResult } from 'sweetalert2';
import { CargandoComponent } from '../pages/cargando/cargando.component';

@Injectable({
	providedIn: 'root'
})
export class EstadoCotizadorService implements CanActivate {

	constructor(private router: Router, private http: HttpClient) { }



	async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		CargandoComponent.mostrarCargando();
		let a = await this.http.get(environment.url + "estado-servicio/actual").toPromise();
		Swal.close();
		if ((<any>a.valueOf()).estadoCotizador) {
			let estado = (<any>a.valueOf()).estadoCotizador;
			if (estado == "HABILITADO") {
				return true;
			} else {
				Swal.fire({
					title: '¡Atención!',
					icon: 'warning',
					html:
						' <b> El Cotizador se encuentra temporalmente deshabilitado</b>',
					showCloseButton: true,
					showCancelButton: false,
					focusConfirm: true,
					confirmButtonText:
						"Continuar"
				});
				this.router.navigate(['inicio'], { queryParams: { returnUrl: state.url } });
				return false;
			}
		}
		Swal.fire({
			title: '¡Atención!',
			icon: 'warning',
			html:
				' <b>Compruebe su conexión</b>',
			showCloseButton: true,
			showCancelButton: false,
			focusConfirm: true,
			confirmButtonText:
				"Continuar"
		});
		this.router.navigate(['inicio'], { queryParams: { returnUrl: state.url } });
		return false;


	}
}

export interface EstadoCotizador {
	estadoCotizador: string,
	id: number,
	nombre: string
}



