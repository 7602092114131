import { Component, Input, OnInit } from '@angular/core';
import { LogisoftStorage } from 'src/app/generico/storage/logisoft-storage';

@Component({
  selector: 'app-cabecera',
  templateUrl: './cabecera.component.html',
  styleUrls: ['./cabecera.component.scss']
})
export class CabeceraComponent implements OnInit {
  
  static titulo=""

  constructor() { }

  ngOnInit() {
  }

  getFecha(){
    return new Date();
  }

  getNombreUsuario(): string {
		return LogisoftStorage.getNombreUsuario().replace("@hab.com.ar", "");
  }
  
  getTitulo(){
    return CabeceraComponent.titulo;
  }

  getImagen(){
    LogisoftStorage.getImagenUsuario();
  }
}
