import { Component, OnInit, Inject } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { InicioComponent } from '../inicio.component';
import { Cotizacion } from '../../cotizacion/cotizacion';

@Component({
  selector: 'app-estados',
  templateUrl: './estados.component.html',
  styleUrls: ['./estados.component.scss']
})
export class EstadosComponent implements OnInit {
  estados=[];
  estado;
  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: any, private _bottomSheetRef: MatBottomSheetRef<InicioComponent>) { 
    this.estados=data.estados;
    this.estado=data.estado;
  }

  ngOnInit() {
  }

  cerrarModal(){
    this._bottomSheetRef.dismiss();
  }


  aceptar(){
    this.cerrarModal();
  }

  cancelar(){
    this.cerrarModal();
  }

  changeEstado(e){
    console.log(e);
  }

  getNombre(){
	return this.data.cotizacion?(<Cotizacion>this.data.cotizacion).estado.nombre:"";
  }
}
