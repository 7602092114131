import { Component, OnInit, Injector, OnChanges, SimpleChanges, Output } from '@angular/core';
import { GenericoService } from 'src/app/generico/servicios/generico.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Configuracion } from 'src/app/generico/configuracion';

import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { FormArray, FormGroup, Validators } from '@angular/forms';
import { MensajesService, TipoMensaje } from 'src/app/generico/mensajes/mensajes.service';
import { EntidadMensaje } from 'src/app/generico/mensajes/entidad-mensaje';
import { Location } from '@angular/common';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

export interface StepType {
  label: string;
  fields: FormlyFieldConfig[];
}

@Component({
  selector: 'app-editar',
  templateUrl: 'editar.component.html',
  styleUrls: ['editar.component.scss']
})
export class EditarComponent<T> implements OnInit, OnChanges {
  serviciosConfig: any;

  constructor(public service: GenericoService<T>, public injector: Injector, public rutaActiva: ActivatedRoute) {

    this.servicioMensajes = this.injector.get<MensajesService>(MensajesService);
    this.location = this.injector.get<Location>(Location);
    this.service.model=this.model;
  }
  mostrarPrev=true;
  jsonEditar;
  cargando = 0;
  modo: number;
  activedStep = 0;  
  model:any = {};
  servicioMensajes: MensajesService;
  location: Location;
  steps: StepType[] = [];

  form = new FormArray(this.steps.map(() => new FormGroup({})), Validators.required);
  options = this.steps.map(() => <FormlyFormOptions>{});

  ngOnChanges(changes: SimpleChanges): void {
   
  }

  ngOnInit() {
    this.setearModo();
    this.jsonEditar = this.service.json.camposEditar;
    this.jsonEditar.forEach(step => {
      step.fields.forEach(field => {
        if (this.modo == Configuracion.MODOS.VER) {
          field.templateOptions.disabled = true;
          this.form.disable({ onlySelf: false, emitEvent: true });
        } else {
          field.templateOptions.disabled = false;
          this.form.disable({ onlySelf: true, emitEvent: true });
        }
        if (field.type == 'select' && !field.templateOptions.multiple) {
          // el nombre del key debe ser igual al del servicio inyectado         
          this.procesarTipoSelect(field);
        }
        if (field.type == 'select' && field.templateOptions.multiple) {
          // el nombre del key debe ser igual al del servicio inyectado         
          this.procesarTipoSelectMultiple(field);
        }
        if (field.type == 'autocomplete') {
          this.procesarTipoAutocomplete(field);
        }

      });
    });
    this.steps = this.jsonEditar;
    this.form = new FormArray(this.steps.map(() => new FormGroup({})), Validators.required);
    this.options = this.steps.map(() => <FormlyFormOptions>{});
    if (this.modo == Configuracion.MODOS.VER) {
      this.form.disable({ onlySelf: false, emitEvent: false });
    }

  }


  procesarTipoSelectMultiple(field: any) {
    const service = this.injector.get<any>(this.serviciosConfig.servicios[field.data.servicio.toLowerCase()].servicio);
    if (field.data.lista && field.data.lista == 'eager') {
      this.cargando++;
    }
    service.getAll().subscribe(r => {
      field.templateOptions.options = this.convertirOpciones(r, field.prop);
      field.templateOptions.compareWith = this.compareWith;
      if (field.data.lista && field.data.lista == 'eager') {
        this.cargando--;
      }
    });
  }

  procesarTipoAutocomplete(field: any) {
    field.templateOptions.compareWith = this.compareWith;
    field.validators= {
      ip: {
        expression: (c) => {if(c.value){return c.value.id;}else{return c;}},
        message: (error, field: FormlyFieldConfig) => `"${field.formControl.value}", ingrese un valor existente.`,
      }
    };
    if (this.model[field.key]) {
      this.model[field.key].toString = function () { return this.model[field.key][field.data.prop] }
    }
    let filtros=field.data.filtros;
        
    field.templateOptions.filter = ((term) => this.getTerm(field.data.servicio.toLowerCase(), term, field.data.prop,filtros,field.data.estrategia,field.min));
  }

  procesarTipoSelect(field: any) {
    const service = this.injector.get<any>(this.serviciosConfig.servicios[field.data.servicio.toLowerCase()].servicio);
    if (field.data.lista && field.data.lista == 'eager') {
      this.cargando++;
    }
    service.getAll().subscribe(r => {
      field.templateOptions.options = this.convertirOpciones(r, field.prop);
      field.templateOptions.compareWith = this.compareWith;
      if (field.data.lista && field.data.lista == 'eager') {
        this.cargando--;
      }
    });
  }

  setearModo() {
    if (this.rutaActiva.snapshot.params.ver && this.rutaActiva.snapshot.params.ver == "ver") {
      this.modo = Configuracion.MODOS.VER;
      this.cargando++;
      this.modoEditar(this.rutaActiva.snapshot.params.id);
      return;
    }
    if (this.rutaActiva.snapshot.params.id) {
      this.modo = Configuracion.MODOS.EDITAR;
      this.cargando++;
      this.modoEditar(this.rutaActiva.snapshot.params.id);
      return;
    }
    this.modo = Configuracion.MODOS.NUEVO;

  }
  modoEditar(id: any) {
    this.service.get(id).subscribe(r => {
      this.model = this.convertirToString(r);

      this.cargando--;

    });
  }
  convertirToString(r: any) {
    this.jsonEditar.forEach(step => {
      step.fields.forEach(field => {
        if (field.type == 'autocomplete') {
          r[field.key].toString = function () { return r[field.key][field.data.prop] }
        }
      });
    });
    return r;
  }
  convertirOpciones(r, opcion): any {
    const opciones = [];
    for (let i = 0; i < r.length; i++) {
      const element = r[i];
      opciones.push({ value: element, label: element[opcion] });
    }
    return opciones;
  }

  prevStep(step) {
    this.activedStep = step - 1;
  }

  nextStep(step) {
    this.activedStep = step + 1;
  }

  submit() {
    
    this.service.nuevo(<any>this.model).subscribe(r => {
      this.servicioMensajes.mostrarMensaje(TipoMensaje.EXITO, new EntidadMensaje(this.service.json.mensaje));
      this.location.back();
    }, e => {
      
    });
  }

  compareWith = function (o1, o2) {
    if (!o2 && !o1) {
      return true;
    }
    if (!o2) {
      return false;
    }
    return o1.id === o2.id;
  };

  getTexto(field) {
    let texto = this.model[field.key];
    if (!texto) {
      return "";
    }
    if (field.type == "select") {
      texto = texto[field.prop];
    }
    if (field.type == "autocomplete") {
      texto = texto[field.data.prop];
    }
    return texto;
  }

  getLabel(field) {
    return field.templateOptions.label;
  }



  /* getTerm(term: any) {
     return this.injector.get(ServiciosConfig.servicios.zona.servicio).getAll();
   }
 */
  getTerm(servicioString, term, arg,filtros,estrategia,min) {
    if(!min){min=0;}
    if(term.length<min){return [];}
    if(!filtros){filtros=[];}
    let filtrosServidor=[];
    filtros.forEach(f => {
      if(this.model[f]){
        filtrosServidor.push([f+".id",this.model[f].id]);  
      }          
    });
    if(estrategia){
      filtrosServidor.push(["strategy",estrategia]);
    }
    filtrosServidor.push ([arg, term]);  
    return this.injector.get(this.serviciosConfig.servicios[servicioString].servicio).
      getPaginaServidorBusqueda(0, 20, filtrosServidor).pipe(map(n => this.convertirRespuestaAutocomplete(n, arg)));
  }
  convertirRespuestaAutocomplete(array, arg: string): any {
    let arrayConvert = [];
    let arrayBusqueda=[];
    if(array.content){
      arrayBusqueda=array.content;
    }else{arrayBusqueda=array;}
    for (let index = 0; index < arrayBusqueda.length; index++) {
      let element = arrayBusqueda[index];
      element.toString = function () { return element[arg] };
      arrayConvert.push(element);
    }
    return arrayConvert;
  }


  seleccionar(value){

  }



}
