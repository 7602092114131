import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { ToastrModule } from 'ngx-toastr';
import { registerLocaleData } from '@angular/common';
import localeEsAr from '@angular/common/locales/es-AR';
registerLocaleData(localeEsAr);

import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { FooterComponent } from './components/footer/footer.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';

import { environment } from '../environments/environment';
import { NotificacionService } from './services/notificacion.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { LoginComponent } from './pages/login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BasicAuthInterceptorService } from './services/autenticacion/basic-auth-interceptor.service';
import { ErrorInterceptorService } from './services/autenticacion/error-interceptor.service';

import { GenericoModule } from './generico/generico.module';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { FormlyMatToggleModule } from '@ngx-formly/material/toggle';
import { MatCardModule } from '@angular/material/card';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule, MatPaginatorIntl } from '@angular/material/paginator';
import { TextoComponent } from './generico/formlys/texto/texto.component';
import { BotonComponent } from './generico/formlys/boton/boton.component';
import { BsModalService, ModalModule, BsDatepickerModule, BsLocaleService, BsDatepickerConfig, BsDaterangepickerConfig } from 'ngx-bootstrap';
import { ModalTablaComponent } from './generico/tabla/modal-tabla/modal-tabla.component';

import { MatStepperModule } from '@angular/material/stepper';

import { BotonIconoComponent } from './generico/formlys/boton-icono/boton-icono.component';

import { MatListModule } from '@angular/material/list';
import { InicioComponent } from './pages/inicio/inicio.component';
import { CarouselModule } from "ngx-owl-carousel-o";
import { ItemCarrouselComponent } from './pages/inicio/item-carrousel/item-carrousel.component';
import { ArticulosComponent } from './pages/articulos/articulos.component';
import { CotizacionComponent } from './pages/cotizacion/cotizacion.component';
import { CotizacionEditarComponent } from './pages/cotizacion/cotizacion-editar/cotizacion-editar.component';
import { LayoutComponent } from './layout/layout/layout.component';
import { TarjetaComponent } from './pages/cotizacion/tarjeta/tarjeta.component';
import { EntidadComponent } from './pages/entidades/entidad/entidad.component';
import { CondicionComponent } from './pages/condiciones/condicion/condicion.component';
import { DetalleCotizacionComponent } from './pages/cotizacion/detalle-cotizacion/detalle-cotizacion.component';
import { NuevoClienteComponent } from './pages/cotizacion/nuevo-cliente/nuevo-cliente.component';
import { CargandoComponent } from './pages/cargando/cargando.component';
import { BackButtonDisableModule } from 'angular-disable-browser-back-button';
import { EstadosComponent } from './pages/inicio/estados/estados.component';
import { InformarArticulosComponent } from './pages/informar-articulos/informar-articulos.component';
import { DetalleRelevamientoComponent } from './pages/informar-articulos/detalle-relevamiento/detalle-relevamiento.component';
import { MiPerfilComponent } from './pages/mi-perfil/mi-perfil.component';
import { GaugeChartModule } from 'angular-gauge-chart';
import { ChartsModule } from 'ng2-charts';
import { ListaCotizacionesComponent } from './pages/lista-cotizaciones/lista-cotizaciones.component';
import { CotizacionesFiltroComponent } from './pages/cotizaciones-filtro/cotizaciones-filtro.component';
import { FiltrosComponent } from './pages/cotizaciones-filtro/filtros/filtros.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { CabeceraComponent } from './components/cabecera/cabecera.component';
import { getSpanishPaginatorIntl } from './generico/configuracion-idioma-pagination';
import { TutorialComponent } from './pages/tutorial/tutorial.component';
import { IconoComponent } from './generico/formlys/icono/icono.component';
import { TextoIconoComponent } from './generico/formlys/texto-icono/texto-icono.component';
import { SubirArchivoComponent } from './pages/informar-articulos/subir-archivo/subir-archivo.component';


export function getDatepickerConfig(): BsDatepickerConfig {
	return Object.assign(new BsDatepickerConfig(), {
		containerClass: 'theme-blue',
		dateInputFormat: 'DD/MM/YYYY'
	});
}
@NgModule({
	declarations: [
		AppComponent,
		NavbarComponent,
		SidebarComponent,
		FooterComponent,
		DashboardComponent,
		LoginComponent, InicioComponent, ItemCarrouselComponent, CotizacionComponent, CotizacionEditarComponent, ArticulosComponent, LayoutComponent, TarjetaComponent, EntidadComponent, CondicionComponent, DetalleCotizacionComponent, NuevoClienteComponent, CargandoComponent, EstadosComponent, InformarArticulosComponent, DetalleRelevamientoComponent, MiPerfilComponent, ListaCotizacionesComponent, CotizacionesFiltroComponent, FiltrosComponent, CabeceraComponent, TutorialComponent, SubirArchivoComponent],
	imports: [
		BrowserModule,
		ModalModule.forRoot(),
		HttpClientModule,
		AppRoutingModule,
		CommonModule,
		BrowserAnimationsModule,
		CollapseModule.forRoot(),
		ToastrModule.forRoot(),
		FormsModule,
		ReactiveFormsModule,
		GenericoModule,
		CarouselModule,
		MatCardModule,
		BackButtonDisableModule.forRoot({
			preserveScrollPosition: true
		}),
		GaugeChartModule,
		ChartsModule,
		InfiniteScrollModule
	],
	exports: [ChartsModule],
	providers: [
		BsModalService,
		BsLocaleService,
		BsDaterangepickerConfig,
		NotificacionService,
		{ provide: HTTP_INTERCEPTORS, useClass: BasicAuthInterceptorService, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptorService, multi: true },
		{ provide: LOCALE_ID, useValue: 'es-Ar' },
		{ provide: MatPaginatorIntl, useValue: getSpanishPaginatorIntl() }
	],
	entryComponents: [FiltrosComponent, DetalleRelevamientoComponent, ArticulosComponent, EstadosComponent, NuevoClienteComponent, DetalleCotizacionComponent, CondicionComponent, EntidadComponent, TextoComponent, BotonComponent, BotonIconoComponent, ModalTablaComponent, IconoComponent, TextoIconoComponent, SubirArchivoComponent],
	bootstrap: [AppComponent]
})
export class AppModule { }
// platformBrowserDynamic().bootstrapModule(AppModule);