import { Injectable } from '@angular/core';
import { GenericoService } from 'src/app/generico/servicios/generico.service';
import { Cotizacion } from './cotizacion';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, of } from 'rxjs';
import { DummyBackend } from 'src/app/dummy-backend';


@Injectable({
	providedIn: 'root'
})
export class CotizacionService extends GenericoService<Cotizacion> {



	urlObjeto = 'cotizaciones';
	json = require('./campos.json');
	constructor(public http: HttpClient) {
		super(http);
		this.url = environment.url;
	}

	getPrecio(condicion, moneda, cod, entidad) {
		const params = new HttpParams()
			.set('condicion', condicion)
			.set('moneda', moneda)
			.set('entidad', entidad);
		return this.http.get(this.url + "articulos/" + cod + "/precio-venta", { params });
	}


	getDescuentos() {
		return this.http.get(this.url + "cotizaciones/descuentos");
	}

	esComparable(numeroCotizacion: number) {
		return this.http.get(this.url + "cotizaciones/" + numeroCotizacion + "/comparable");
	}

	getDescuentosArticulo(d: any, condicion: any) {
		let parametros: HttpParams = new HttpParams().set("condicion", condicion);
		return this.http.get(this.url + "articulos/" + d.idArticulo.toString() + "/cotizaciones/descuento", { params: parametros });
	}

	//Descuento del limite por condicion de pago
	//Si el valor de hasta es > 0 se mostraria limite descuento , el valor de hasta X% , si el valor de hasta es 0 , poner la leyenda sin descuento
	getRangoDescuentoCondicion(idCondicion: number): Observable<DescuentoCondicion> {

		let parametros: HttpParams = new HttpParams().set("condicion", idCondicion.toString());
		return this.http.get<DescuentoCondicion>(this.url + "cotizacion/limite-descuentos-condicion/filtro", { params: parametros });
	}


	//override
	nuevo(objeto: Cotizacion) {
		let url = this.url + "cotizaciones";
		return this.http.post(url, objeto);
	}

	actualizar(objeto: Cotizacion) {
		let url = this.url + "cotizaciones/" + objeto.id;
		return this.http.put(url, objeto);
	}

	//override
	getAll() {
		let url = this.url + "cotizaciones?sort=numero,DESC&sort=numeroHijo,DESC&page=0&size=30";
		return this.http.get(url);
	}

	getAllFiltrados(filtros?: any[]) {
		let params = new HttpParams()
			.set('sort', 'id,DESC')
			.set('size', environment.page.size.toString())
			.set('pagination', 'true');
		if (filtros) {
			filtros.forEach(element => {
				params = params.set(element.param, element.value)
			});
			if (filtros.length > 1) {
				params = params.set('strategy', 'AND');
			}
		}

		let url = this.url + "cotizaciones";
		return this.http.get(url, { params });
	}


	cambiarCondicion(cotizacion: Cotizacion, idCondicion: number): Observable<Cotizacion> {
		console.log(Object.assign({}, cotizacion));
		const params = new HttpParams()
			.set('condicion', idCondicion.toString());
		return <Observable<any>>this.http.put(this.getUrl() + "/cambio-condicion", cotizacion, { params });
	}


	clonar(idCotizacion: number, idCondicion: number) {
		const params = new HttpParams().set('cotizacion', idCotizacion.toString()).set('condicion', idCondicion.toString());
		return this.http.post(this.getUrl() + '/clonar', {}, { params });
	}

	descargarCotizacion(cotizacion: Cotizacion) {
		return this.http.get(
			this.getUrl() + '/imprimir/' + cotizacion.hash, { responseType: 'blob' });
	}

	compartir(c: Cotizacion) {
		return this.http.put(environment.url + "cotizaciones/" + c.id + "/compartir/", {});
	}

	aprobar(c: Cotizacion) {
		return this.http.put(environment.url + "cotizaciones/" + c.id + "/aprobar/", {});
	}

	desaprobar(c: Cotizacion, motivo: string) {
		const params = new HttpParams().set('motivo', motivo);
		return this.http.put(environment.url + "cotizaciones/" + c.id + "/desaprobar/", {}, { params });
	}

	enviarMesa(c: Cotizacion) {
		return this.http.put(environment.url + "cotizaciones/" + c.id + "/enviar-mesa/", {});
	}

	getEstadisticas(idVendedor: number) {
		let params = new HttpParams()
			.set('fechaDesde', '01-01-2021')
			.set('fechaHasta', '31-12-2021')
			.set('idVendedor', idVendedor.toString());
		return this.http.get(this.url + "cotizacion/estadisticas/totales", { params });
	}

}


export interface DescuentoCondicion {
	idCondicionPago: number;
	nombreCondicion?: string;
	desde: number;
	hasta: number;

}