import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';
import { AccionesGenerales } from '../../formlys/acciones/acciones-generales';
import { TablaComponent } from '../tabla.component';

@Component({
  selector: 'app-modal-tabla',
  templateUrl: './modal-tabla.component.html',
  styleUrls: ['./modal-tabla.component.scss']
})
export class ModalTablaComponent<T> implements OnInit,AccionesGenerales {
   item={};
   columnas=[];
   acciones=[];
   observador: TablaComponent<T>;
  constructor(public modalRef:BsModalRef) { }

  getThis(){
    return this;
  }
  nuevo() {
    this.modalRef.hide();
    this.observador.nuevo();
  }
  editar(id: any) {
    this.modalRef.hide();
    this.observador.editar(id);
  }
  eliminar(id: any) {
    this.modalRef.hide();
    this.observador.eliminar(id);
  }
  ver(id: any) {
    this.modalRef.hide();
    this.observador.ver(id);
  }

  ngOnInit() {
  }


  getTitulo():string{
    const titulo = this.columnas.find(col => col.titulo);
    if(titulo){return this.item[titulo.prop];}
    return "Logisoft";
  }

}
