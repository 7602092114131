import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { GenericoService } from 'src/app/generico/servicios/generico.service';
import { Entidad } from './entidad';
import { Observable } from 'rxjs';
import { Pagina } from 'src/app/generico/paginacion/pagina';
import { map } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class EntidadesService extends GenericoService<Entidad> {
	urlObjeto = 'entidades';
	json = require('./campos.json');
	constructor(public http: HttpClient) {
		super(http);
		this.url = environment.url;
	}

	//override

	getPaginaServidorBusqueda(pageIndex: number, pageSize: number, parametros) {
		parametros.push(["sort", "razonSocial,ASC"]);

		let params = new HttpParams()
			.set('page', pageIndex.toString())
			.set('pagination', 'true')
			.set('size', pageSize.toString());

		for (let i = 0; i < parametros.length; i++) {
			const p = parametros[i];
			params = params.append(p[0], p[1]);
		}
		return <Observable<Pagina<Entidad>>>this.http.get(this.getUrl(), { params }).pipe(map(
			(rta: Pagina<Entidad>) => {
				console.log(rta);
				if (rta.first) {
					let prospecto: Entidad;
					prospecto = new Entidad();
					prospecto.id = 11128;
					prospecto.razonSocial = "CLIENTE PROSPECTO";
					prospecto.prospecto = true;
					rta.content.splice(0, 0, prospecto);
				}
				return rta;
			}
		));
	}

}
