import { CotizacionService } from './../cotizacion/cotizacion.service';
import { Component, OnInit } from '@angular/core';
import { LogisoftStorage } from 'src/app/generico/storage/logisoft-storage';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { Label } from 'ng2-charts';
import { CabeceraComponent } from 'src/app/components/cabecera/cabecera.component';
@Component({
	selector: 'app-mi-perfil',
	templateUrl: './mi-perfil.component.html',
	styleUrls: ['./mi-perfil.component.scss']
})
export class MiPerfilComponent implements OnInit {
	titulo = "MI PERFIL";

	getFecha() {
		return new Date();
	}

	getNombreUsuario() {
		return LogisoftStorage.getNombreUsuario();
	}

	data: any = {};
	public start: Date = new Date("10/07/2017");
	public end: Date = new Date("11/25/2017");


	constructor(private service: CotizacionService) {
		CabeceraComponent.titulo = "MI PERFIL"
		let idVendedor = LogisoftStorage.getUsuario().idVendedor;
		this.service.getEstadisticas(idVendedor).subscribe(r => {
			this.data = r;
			this.needleValue = this.data.margenPromedio;
		}, e => {
			// this.finCargando();
		});
	}


	//grafico acelerador
	public canvasWidth = 400
	public needleValue = 2
	public centralLabel = ''
	public name = 'Facturado'
	public bottomLabel = ''
	public options = {
		hasNeedle: true,
		outerNeedle: false,
		needleColor: "orange",
		needleStartValue: 0,
		arcColors: ["rgb(255,84,84)", "rgb(239,214,19)", "rgb(61,204,91)"],
		arcDelimiters: [33, 66],
		rangeLabel: ["", ""],
		rangeLabelFontSize: 17,
	}
	//fin grafico acelerador

	//grafico barra
	public barChartOptions: ChartOptions = {
		responsive: true,
		// We use these empty structures as placeholders for dynamic theming.
		scales: { xAxes: [{}], yAxes: [{}] },
		plugins: {
			datalabels: {
				anchor: 'end',
				align: 'end',
			}
		}
	};
	public barChartLabels: Label[] = ['2006', '2007', '2008', '2009', '2010', '2011', '2012'];
	public barChartType: ChartType = 'bar';
	public barChartLegend = true;
	public barChartPlugins = [pluginDataLabels];

	public barChartData: ChartDataSets[] = [
		{ data: [65, 59, 80, 81, 56, 55, 40], label: 'Cotizaciones' },
	];

	//fin grafico barra

	ngOnInit() {

		this.canvasWidth = screen.width / 2
	}

}
