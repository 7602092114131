import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class AppService {

	isSidebarPinned = false;
	isSidebarToggeled = false;

	constructor(public http: HttpClient) { }

	toggleSidebar() {
		this.isSidebarToggeled = !this.isSidebarToggeled;
	}

	toggleSidebarPin() {
		this.isSidebarPinned = !this.isSidebarPinned;
	}

	getSidebarStat() {
		return {
			isSidebarPinned: this.isSidebarPinned,
			isSidebarToggeled: this.isSidebarToggeled
		}
	}

	getVersion(): Observable<Object> {
		var subject = new Subject<Object>();
		this.http.get('version.txt', { responseType: 'text' as 'json' }).subscribe(data => {
			subject.next(data.toString());
		}, error => {
			console.log(error);
		});
		return subject.asObservable();
	}

}
