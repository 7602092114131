import { MenuItem } from './menu-item';

export class Menu {
    nombre:string;
    items:MenuItem[]=[];
    
    constructor(nombre:string,items:MenuItem[]){
        this.nombre=nombre;
        this.items=items;
    }
}
