import { Component, OnInit, HostListener, Injector, ChangeDetectorRef } from '@angular/core';
import { Platform } from '@angular/cdk/platform';
import { Cotizacion } from '../cotizacion/cotizacion';
import Swal, { SweetAlertResult, SweetAlertIcon } from 'sweetalert2';
import { CondicionComponent } from '../condiciones/condicion/condicion.component';
import { EstadosComponent } from '../inicio/estados/estados.component';
import { Router } from '@angular/router';
import { TablaComponent } from 'src/app/generico/tabla/tabla.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { PantallaCargando } from '../pantalla-cargando';
import { CotizacionService } from '../cotizacion/cotizacion.service';
import { EstadosServicee } from '../inicio/estados.service';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { LogisoftStorage } from 'src/app/generico/storage/logisoft-storage';
import { ColoresService } from '../cotizacion/colores.service';
import { HttpErrorResponse } from '@angular/common/http';
import { MensajesService, TipoMensaje } from 'src/app/generico/mensajes/mensajes.service';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-lista-cotizaciones',
	templateUrl: './lista-cotizaciones.component.html',
	styleUrls: ['./lista-cotizaciones.component.scss'],

})
export class ListaCotizacionesComponent extends PantallaCargando implements OnInit {
	observador;
	constructor(public mensajesService: MensajesService, public coloresService: ColoresService,
		private modalAbajo: MatBottomSheet, private estadosService: EstadosServicee, public modalService: BsModalService, private router: Router, private _servicio: CotizacionService, public injector: Injector,
		public plataforma: Platform) {
		super(injector);
	}

	ngOnInit() {

	}
	actualizar() {
		this.inicioCargando();
		if (!LogisoftStorage.isColores()) {
			this.coloresService.getAll().subscribe(r => {
				LogisoftStorage.setColores(r);
				this.cargarCotizaciones();
			}, e => {
				console.log(e);
				this.finCargando();
			});
		} else {
			this.cargarCotizaciones();
		}
	}

	modalRef: BsModalRef;
	cotizaciones = [];

	cargarCotizaciones = function () {
		this._servicio.getAll().subscribe((rta: any[]) => {
			this.cotizaciones = Cotizacion.conversionArray(rta);
			this.finCargando();
		}, e => {
			console.log(e);
			this.finCargando();
		});
	}

	deslizarL(i) {
		if (!this.cotizaciones[i].deslizar) {
			document.getElementById("cotizacion" + i).classList.remove("animated");
			document.getElementById("cotizacion" + i).classList.remove("slideInLeft");
			document.getElementById("cotizacionB" + i).classList.remove("animated");
			document.getElementById("cotizacionB" + i).classList.remove("slideOutRight");
			document.getElementById("cotizacion" + i).classList.add("animated");
			document.getElementById("cotizacion" + i).classList.add("slideOutLeft");
			document.getElementById("cotizacionB" + i).classList.add("animated");
			document.getElementById("cotizacionB" + i).classList.add("slideInRight");
			this.cotizaciones[i].deslizar = true;
		}
	}

	deslizarR(i) {
		if (this.cotizaciones[i].deslizar) {
			document.getElementById("cotizacion" + i).classList.remove("animated");
			document.getElementById("cotizacion" + i).classList.remove("slideOutLeft");
			document.getElementById("cotizacionB" + i).classList.remove("animated");
			document.getElementById("cotizacionB" + i).classList.remove("slideInRight");
			document.getElementById("cotizacion" + i).classList.add("animated");
			document.getElementById("cotizacion" + i).classList.add("slideInLeft");
			document.getElementById("cotizacionB" + i).classList.add("animated");
			document.getElementById("cotizacionB" + i).classList.add("slideOutRight");
			this.cotizaciones[i].deslizar = false;
		}
	}

	editarCotizacion(c: Cotizacion, i) {
		this.deslizarR(i);
		if (c.getVencimiento() < 0) {
			Swal.fire({
				title: '¡Atención!',
				icon: 'warning',
				html:
					'Usted está a punto de editar, ' +
					'una cotización vencida ' +
					'si continúa <b> se recalculará el precio.</b>',
				showCloseButton: true,
				showCancelButton: true,
				focusConfirm: true,
				confirmButtonText:
					"Continuar",
				confirmButtonAriaLabel: 'Recalcular cotización',
				cancelButtonText:
					"Cancelar",
				cancelButtonAriaLabel: "Volver al inicio"
			}).then((value: SweetAlertResult) => {
				if (value.value) {
					this.router.navigate(['cotizacion/recalcular/' + c.id]);
				}
			})
		} else {
			this.router.navigate(['cotizacion/' + c.id]);
		}

	}

	verCotizacion(c: Cotizacion, i) {
		this.deslizarR(i);
		this.router.navigate(['cotizacion', "ver", c.id]);

	}
	public abrirModalBusqueda(x, texto) {
		TablaComponent.active = 0;
		this.modalRef = this.modalService.show(x);
		this.modalRef.content.busqueda.texto = texto;
		this.modalRef.content.textoBuscar = texto;
		this.modalRef.content.setPaginacion(5);
		this.modalRef.content.iconoInfo = "fas fa-angle-right fa-lg";
		this.modalRef.content.tituloCentrado = true;
		this.modalRef.content.elemento = this;
		TablaComponent.active = 1;
	}


	clonarCotizacion(cotizacion: Cotizacion) {
		this.mensajeClonar().then(
			value => {
				if (value.value) {
					this.abrirModalBusqueda(CondicionComponent, "");

					let elemento = this;
					this.modalRef.content.abrirInfo = function (item) {
						let texto = '¿ Está seguro que desea clonar la cotización ';
						let icono: SweetAlertIcon = 'question';
						if (item.id == cotizacion.idCondicionPago) {
							texto = texto + "con la misma condición de pago ";
							icono = 'warning';
						}
						texto = texto + "?";

						Swal.fire(
							{
								title: 'Clonar'
								, text: texto
								, showCancelButton: true
								, showConfirmButton: true,
								cancelButtonText: 'Cancelar',
								confirmButtonText: 'Aceptar',
								icon: icono,
								cancelButtonColor: 'red',
								confirmButtonColor: 'green'
							}).then(r => {
								elemento.inicioCargando();
								if (r.value) {
									elemento._servicio.clonar(cotizacion.id, item.id).subscribe(() => {
										elemento.actualizar();
										elemento.modalRef.hide();
										elemento.finCargando();
									}, (e: HttpErrorResponse) => {
										Swal.fire({ icon: "error", html: e })
									});

								} else {
									elemento.modalRef.hide();
									elemento.finCargando();
								}
							});
					}
				}
			}
		)

	}

	async compartirCotizacion(c: Cotizacion) {
		this.mensajeCompartir().then((value) => {
			if (value.value) {
				if (c.getEstado().codestado == "APROB_PEN" || c.estado.codestado == "APROB_MESA") {
					this.inicioCargando();
					this._servicio.compartir(c).subscribe(() => {
						this.cargarCotizaciones();
						this.finCargando();
					}, (e: HttpErrorResponse) => {
						Swal.fire({ icon: "error", html: e })
					});
				}
				if (this.plataforma.isBrowser) {
					window.open(environment.urlPDF + "cotizaciones/imprimir/" + c.hash, "_blank");
				} else {
					(<any>window).navigator.share({ text: "Cotización " + this.getNumero(c), title: "HAB", url: environment.urlPDF + "cotizaciones/imprimir/" + c.hash })
				}

			}
		});
	}


	async imprimirCotizacion(c: Cotizacion) {

		this.inicioCargando();
		this._servicio.esComparable(c.numero).subscribe((rta: any) => {
			this.finCargando();
			if (rta.valor) {
				Swal.fire({
					title: "Imprimir",
					icon: "question"
					, text: "¿Desea mostrar comparación de condiciones de pago clonadas? (De seleccionar no, se mostrará la cotización individual)",
					showCancelButton: true,
					showCloseButton: true,
					showConfirmButton: true,
					confirmButtonText: "Si",
					"confirmButtonColor": "#d33",
					"cancelButtonColor": "#3085d6",
					cancelButtonText: "No",
				}).then((value: SweetAlertResult) => {
					this.imprimir(c.hash, value.value);
				});
			} else {
				this.imprimir(c.hash, false);
			}
		}, (e: HttpErrorResponse) => {
			Swal.fire({ icon: "error", html: e })
		});


	}

	private imprimir(hash: string, compara: boolean) {
		if (this.plataforma.isBrowser) {
			window.open(environment.urlPDF + "cotizaciones/imprimir/" + hash + "?compara=" + compara, "_blank");

		} else {
			window.location.href = environment.urlPDF + "cotizaciones/imprimir/" + hash + "?compara=" + compara;
		}
	}

	aprobar(c: Cotizacion) {
		this.mensajeAprobar().then((value) => {
			if (value.value) {
				this.inicioCargando();
				this._servicio.aprobar(c).subscribe(() => {
					this.cargarCotizaciones();
					this.finCargando();
				}, (e: HttpErrorResponse) => {
					Swal.fire({ icon: "error", html: e })
				});

			}
		});
	}

	desaprobar(c: Cotizacion) {
		this.mensajeDesaprobar().then((value) => {

			if (value.value) {
				this.inicioCargando();
				this._servicio.desaprobar(c, value.value).subscribe(() => {
					this.cargarCotizaciones();
					this.finCargando();
				}, (e: HttpErrorResponse) => {
					Swal.fire({ icon: "error", html: e })
				});

			}
		});
	}

	enviarMesa(c: Cotizacion) {
		this.mensajeEnviarMesa().then((value) => {
			if (value.value) {
				this.inicioCargando();
				this._servicio.enviarMesa(c).subscribe(() => {
					this.cargarCotizaciones();
					this.finCargando();
				}, (e: HttpErrorResponse) => {
					Swal.fire({ icon: "error", html: e })
				});
			}
		});
	}

	mensajeCompartir(): Promise<SweetAlertResult> {
		return Swal.fire({
			title: "Compartir",
			text: "¿ Esta seguro que desea compartir la cotización? ",
			icon: "question",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Si",
			cancelButtonText: "No"
		})
	}

	mensajeClonar(): Promise<SweetAlertResult> {
		return Swal.fire({
			title: "Clonar",
			text: "¿ Esta seguro que desea clonar la cotización? ",
			icon: "question",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Si",
			cancelButtonText: "No"
		})
	}

	mensajeRehacer(): Promise<SweetAlertResult> {
		return Swal.fire({
			title: "Rehacer",
			text: "¿ Esta seguro que desea rehacer la cotización? ",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Si",
			cancelButtonText: "No"
		})
	}


	mensajeEnviarMesa(): Promise<SweetAlertResult> {
		return Swal.fire({
			title: "Enviar",
			text: "¿ Esta seguro que desea enviar la cotización a la mesa? ",
			icon: "question",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Si",
			cancelButtonText: "No"
		})
	}

	mensajeAprobar(): Promise<SweetAlertResult> {
		return Swal.fire({
			title: "Aprobar",
			text: "¿ Esta seguro que desea confirmar la cotización? ",
			icon: "question",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Si",
			cancelButtonText: "No"
		})
	}


	mensajeDesaprobar() {

		return Swal.fire({
			title: "Declinar",
			icon: "warning",
			text: "¿ Esta seguro que desea declinar la cotización? Ingrese Motivo ",
			input: 'textarea',
			inputPlaceholder: "Motivo",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Aceptar",
			cancelButtonText: "Cancelar"
		});
	}


	getNumero(c: Cotizacion) {
		let numero = c.numero.toString();
		if (numero.length < 4) {
			for (let i = 0; i < (4 - c.numero.toString().length); i++) {
				numero = "0" + numero;
			}
		}
		return "N° " + numero + '' + (c.numeroHijo ? '-' + c.numeroHijo : '');
	}

	blobToFile = (theBlob: Blob, fileName: string): File => {
		var b: any = theBlob;
		//A Blob() is almost a File() - it's just missing the two properties below which we will add
		b.lastModifiedDate = new Date();
		b.name = fileName;

		//Cast to a File() type
		return <File>theBlob;
	}

	cambiarEstado(c: Cotizacion) {
		this.inicioCargando();
		this.estadosService.getAll().subscribe(r => {
			this.finCargando();
			this.modalAbajo.open(EstadosComponent, {
				data: {
					estado: r[0],
					estados: r,
					cotizacion: c
				}
			});
		}, (e: HttpErrorResponse) => {
			Swal.fire({ icon: "error", html: e })
		});
	}

	rehacer(c: Cotizacion, i) {
		this.mensajeRehacer().then(value => {
			if (value.value) {
				this.deslizarR(i);
				this.router.navigate(['cotizacion', "rehacer", c.id]);
			}
		})

	}


}
