import { AppService } from './../../services/app.service';
import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/services/autenticacion/authentication.service';
import { first, timeout } from 'rxjs/operators';
import { Router } from '@angular/router';

import Swal from 'sweetalert2'
import { CargandoComponent } from '../cargando/cargando.component';
import { LogisoftStorage } from 'src/app/generico/storage/logisoft-storage';
import { HttpClient } from '@angular/common/http';


@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
	user;
	pass;
	version;
	constructor(public authenticationService: AuthenticationService, public router: Router, public appService: AppService) { }

	ngOnInit() {
		this.version = this.appService.getVersion().subscribe(version => this.version = version);
		console.log(this.version);
		this.authenticationService.logout();
	}

	iniciarSesion() {
		if (this.user == "" || this.pass == "" || !this.user || !this.pass) {
			Swal.fire({
				allowOutsideClick: true,
				icon: 'warning',
				text: 'Debe ingresar datos'
			});
			return;
		}
		Swal.fire({
			allowOutsideClick: false,
			icon: 'info',
			text: 'Espere por favor...'
		});
		CargandoComponent.mostrarCargando();

		this.authenticationService.login(this.user, this.pass)
			.pipe(first())
			.subscribe(
				data => {

					Swal.close();
					// if (LogisoftStorage.getNoVolverAMostrarTutorial()) {
					this.router.navigate(["/inicio"]);
					// } else {
					// this.router.navigate(["/tutorial"]);
					// }


				}, (error) => {
					Swal.close();
					Swal.fire({
						allowOutsideClick: false,
						icon: 'error',
						title: 'Atención',
						confirmButtonText: "Aceptar",
						text: 'Nombre de usuario o contraseña inválido'
					});



				});
	}

}
