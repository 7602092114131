import { Component, OnInit, ViewChild, ChangeDetectorRef, HostListener, Injector } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { FiltrosComponent } from './filtros/filtros.component';
import { ListaCotizacionesComponent } from '../lista-cotizaciones/lista-cotizaciones.component';
import { EstadosServicee } from '../inicio/estados.service';
import { PantallaCargando } from '../pantalla-cargando';
import { LogisoftStorage } from 'src/app/generico/storage/logisoft-storage';
import { CotizacionService } from '../cotizacion/cotizacion.service';
import { Cotizacion } from '../cotizacion/cotizacion';
import { ColoresService } from '../cotizacion/colores.service';
import { LogisoftUtil } from 'src/app/generico/logisoft-util';
import { CabeceraComponent } from 'src/app/components/cabecera/cabecera.component';

@Component({
	selector: 'app-cotizaciones-filtro',
	templateUrl: './cotizaciones-filtro.component.html',
	styleUrls: ['./cotizaciones-filtro.component.scss']
})
export class CotizacionesFiltroComponent extends PantallaCargando implements OnInit {

	@ViewChild("listaCotizaciones")
	listaCotizaciones: ListaCotizacionesComponent;
	filtros;
	filtrosMostrar = [];
	filtrosEnviar: any[];



	constructor(public injector: Injector, private coloresService: ColoresService, private cotizacionService: CotizacionService, private cd: ChangeDetectorRef, private modalAbajo: MatBottomSheet, public estadosService: EstadosServicee) {
		super(injector);
		CabeceraComponent.titulo = "MIS COTIZACIONES";

	}

	ngOnInit() {
		this.listaCotizaciones.observador = this;
		this.listaCotizaciones.cargarCotizaciones = function () {
			let filtros = this.observador.convertirFiltros();
			if (this.observador.paginaActual) {
				if (this.observador.paginaActual.last) {
					return;
				}
				let paginaNueva: number = this.observador.paginaActual.number + 1;
				filtros.push({ param: "page", value: paginaNueva.toString() })
			} else {
				filtros.push({ param: "page", value: "0" })
			}

			(<CotizacionService>this._servicio).getAllFiltrados(filtros).subscribe((rta: any) => {
				this.cotizaciones = this.cotizaciones.concat(Cotizacion.conversionArray(rta.content));
				let pagina = Object.assign({}, rta);
				delete pagina.content;
				this.observador.paginaActual = pagina;
				this.finCargando();
			});
		}
		this.funcionScroll = this;
		this.actualizar();
	}

	fScroll() {
		this.listaCotizaciones.cargarCotizaciones();
	}
	convertirFiltros(): any[] {
		let mostrar = [];
		if (!this.filtros) { return [] }
		if (this.filtros.estado) {
			mostrar.push({ param: "estado.codestado", value: this.filtros.estado.codestado })
		}
		if (this.filtros.fechaDesde) {
			mostrar.push({ param: "fecha>i", value: new Date(this.filtros.fechaDesde).toISOString() })
		}
		if (this.filtros.fechaHasta) {
			mostrar.push({ param: "fecha<i", value: new Date(this.filtros.fechaHasta).toISOString() })
		}

		if (this.filtros.condicion) {
			mostrar.push({ param: "idCondicionPago", value: this.filtros.condicion.id });
		}
		if (this.filtros.razonSocial) {
			mostrar.push({ param: "idEntidad", value: this.filtros.razonSocial.id });
		}

		return mostrar;
	}


	abrirFiltros() {
		this.inicioCargando()
		this.estadosService.getAll().subscribe((r: any[]) => {
			this.modalAbajo.open(FiltrosComponent, {
				data: {
					estados: r.sort(function (a, b) {
						if (a.nombre < b.nombre) {
							return -1;
						};
						return 1;
					})
					,
					elemento: this,
					filtros: Object.assign({}, this.filtros)
				}
			});
			this.finCargando();
		})

	}

	quitarFiltro(i, filtro) {
		this.filtrosMostrar.splice(i, 1);
		filtro.items.forEach(p => {
			this.filtros[p] = null;
		});
		this.actualizar();
	}

	actualizar() {
		this.paginaActual = null;
		this.listaCotizaciones.cotizaciones = [];
		this.listaCotizaciones.actualizar();
		this.cd.detectChanges();
	}



}

