import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CortevaService {

  constructor(private http:HttpClient) { }

  getDOVs():Observable<DOV[]>{
	  return <Observable<DOV[]>>this.http.get(environment.url + "corteva-volumen");
  }

}

export interface DOV{
  cantDesde:number;
  cantHasta:number;
  importeDescuento:number;
  comentario:string;
  habilitado:boolean;
}
