import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { OwlOptions,CarouselComponent } from 'ngx-owl-carousel-o';
import { PartialObserver } from 'rxjs';
import { LogisoftStorage } from 'src/app/generico/storage/logisoft-storage';
import { TutorialService } from 'src/app/services/tutorial/tutorial.service';

@Component({
  selector: 'app-tutorial',
  templateUrl: './tutorial.component.html',
  styleUrls: ['./tutorial.component.scss']
})
export class TutorialComponent implements OnInit {
  customOptions: OwlOptions = {
		loop: false,
		mouseDrag: true,
		touchDrag: true,
		pullDrag: true,
		dots: true,
		navSpeed: 700,
		items: 1,
		autoHeight: false,
		autoWidth: true,
    nav: false,    
		responsive: {
			// breakpoint from 0 up
			0: {
				items: 1
			},
			// breakpoint from 480 up
			480: {
				items: 1
			},
			// breakpoint from 768 up
			768: {
				items: 1
			}
		}
  }
  @ViewChild("owl")owl:CarouselComponent;
  items=[]
  constructor(private service:TutorialService,private router:Router) { }

  ngOnInit() {
    this.service.getTutorial().subscribe(tutorial=>{
        this.items=tutorial.sort(function(a,b){
          if(a.orden>b.orden){
            return 1;
          }
          return -1;
        });
        console.log(this.owl);        
    })
  }

  atras(){
    this.owl.prev()
  }

  siguiente(){
    if(!this.isUltimo){
      this.owl.next();
    }else{
      this.router.navigate(['inicio']);
    }
    
  }


  get noVolverAMostrar(){
    return LogisoftStorage.getNoVolverAMostrarTutorial();
  }

  set noVolverAMostrar(b:boolean){
    LogisoftStorage.setNoVolverAMostrarTutorial(b);
  }
  
  get isUltimo(){
    return this.owl.slidesData.length>0?this.owl.slidesData[this.owl.slidesData.length-1].isActive:false;
  }
}
