import { Component, OnInit, ViewChild, ViewContainerRef, Type, Input, AfterViewInit, ComponentFactoryResolver } from '@angular/core';

import { TextoComponent } from '../texto/texto.component';
import { ElementoTablaComponent } from '../elemento-tabla/elemento-tabla.component';
import { BotonComponent } from '../boton/boton.component';
import { BotonIconoComponent } from '../boton-icono/boton-icono.component';
import { AccionesGenerales } from '../acciones/acciones-generales';
import { IconoComponent } from '../icono/icono.component';
import { TextoIconoComponent } from '../texto-icono/texto-icono.component';

@Component({
  selector: 'app-field-generico',
  templateUrl: './field-generico.component.html',
  styleUrls: ['./field-generico.component.scss']
})
export class FieldGenericoComponent implements OnInit, AfterViewInit {
  @Input() col: any = { prop: '' };
  @Input() elemento: any;
  @Input() observador:AccionesGenerales;

  @ViewChild('dynamic', {read: ViewContainerRef}) 
  viewContainerRef: ViewContainerRef;

  constructor(private cfr: ComponentFactoryResolver) {
  }
  ngOnInit() {
  }

  ngAfterViewInit(): void {
    let compFactory = this.cfr.resolveComponentFactory(this.getComponent(this.col.tipo));
    let ref = this.viewContainerRef.createComponent(compFactory);
    ref.instance.col = this.col;
    ref.instance.elemento = this.elemento;
    ref.instance.observador=this.observador;
  }

  getComponent(prop: string): Type<ElementoTablaComponent> {
    switch (prop) {
      case 'texto':
        return TextoComponent;
        break;
      case 'boton':
        return BotonComponent;
        break;
      case 'boton-icono':
        return BotonIconoComponent;
        break;
      case 'icono':
        return IconoComponent;
        break;
      case 'texto-icono':
        return TextoIconoComponent;
         break;
      default:
        return TextoComponent;
        break;
    }
  }




}
