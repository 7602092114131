export class EntidadMensaje {
    articulo:string=null;
    singular:string=null;
    articuloPlural:string=null;
    plural:string=null;
    femenino:boolean=false;
   

    constructor(entidadO:any){       
        //recorro las propiedades del nuevo objeto
        //este metodo se utiliza para sobreescribir las propiedades del objeto        
        Object.keys(this).forEach(k => {
            
            if(Object.keys(entidadO).includes(k)){
                this[k]=entidadO[k];
            }
        });
    }


    
    getSingular():string{
        return this.singular;
    }

    getPlural():string{
        if(this.plural!=null){return this.plural}
        return this.singular+"s";
    }

    getSingularConArticulo():string{
        if(this.articulo!=null){
            return this.articulo+" "+this.getSingular();
        }
        this.articulo="El";
        if(this.femenino){this.articulo="La";}
        return this.articulo+" "+this.getSingular();
    }

    getPluralConArticulo():string{
        if(this.articuloPlural!=null){
            return this.articuloPlural+" "+this.getPlural();
        }
        this.articuloPlural="Los";
        if(this.femenino){this.articulo="Las";}
        return this.articuloPlural+" "+this.getPlural();
    }
}
